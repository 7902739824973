import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { add_weight_api, update_weight_api } from "src/DAL/exercise/exercise";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const select_array = [
  {
    name: "chest",
    label: "Chest",
    require: false,
  },
  {
    name: "waist",
    label: "Waist",
    require: false,
  },
  {
    name: "hip",
    label: "Hip",
    require: false,
  },
  {
    name: "arm",
    label: "Arm",
    require: false,
  },
  {
    name: "thigh",
    label: "Thigh",
    require: false,
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "15%",
    marginBottom: "15%",
  },
}));

const AddWeight = ({ setOpenWeight, weight_list, editValues, userPre }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isAddMer, setIsAddMer] = useState(false);

  const now = new Date();
  const currentDate = now.toISOString().split("T")[0];
  const currentTime = now.toTimeString().slice(0, 5);

  const [inputs, setInputs] = useState({
    current_weight: "",
    body_fat: "",
    chest: "",
    waist: "",
    hip: "",
    arm: "",
    thigh: "",
    note: "",
    date: currentDate,
    time: currentTime,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputs.body_fat && !inputs.current_weight) {
      enqueueSnackbar(
        "Please enter either your Current Weight or Body Fat, or both if available.",
        { variant: "error" }
      );
      return;
    }

    setIsFormLoading(true);
    let postData = {
      current_weight: inputs.current_weight || 0,
      body_fat: inputs.body_fat || 0,
      chest: inputs.chest,
      waist: inputs.waist,
      hip: inputs.hip,
      arm: inputs.arm,
      thigh: inputs.thigh,
      note: inputs.note,
      date: inputs.date,
      time: inputs.time,
    };

    if (!isAddMer) {
      postData.chest = "";
      postData.waist = "";
      postData.hip = "";
      postData.arm = "";
      postData.thigh = "";
    }

    const result = editValues
      ? await update_weight_api(postData, editValues?._id)
      : await add_weight_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsFormLoading(false);
      setOpenWeight(false);
      weight_list();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsFormLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (editValues) {
      const is_show =
        editValues?.arm ||
        editValues?.hip ||
        editValues?.thigh ||
        editValues?.waist ||
        editValues?.chest;
      setIsAddMer(is_show);
      setInputs((prevInputs) => ({
        ...prevInputs,
        ...editValues,
        date: editValues?.date
          ? editValues?.date.split("T")[0]
          : prevInputs.date,
      }));
    }
  }, [editValues]);

  return (
    <div className="px-4 pb-3">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-3 mt-3">
            <TextField
              fullWidth
              type="date"
              id="outlined-basic"
              label="Date"
              variant="outlined"
              name="date"
              value={inputs.date}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-3 mt-3">
            <TextField
              fullWidth
              type="time"
              id="outlined-basic"
              label="Time"
              variant="outlined"
              name="time"
              value={inputs.time}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-3 mt-3">
            <TextField
              fullWidth
              type="number"
              id="outlined-basic"
              label="Current Weight"
              variant="outlined"
              name="current_weight"
              value={inputs.current_weight}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {userPre?.weight_unit ? userPre?.weight_unit : ""}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="col-12 col-md-3 mt-3">
            <TextField
              fullWidth
              type="number"
              id="outlined-basic"
              label="Body Fat"
              variant="outlined"
              name="body_fat"
              value={inputs.body_fat}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </div>
          <div className="col-12">
            <div className="go_to_community text-end p-0 mt-3">
              <span
                className="pointer"
                onClick={() => {
                  setIsAddMer(!isAddMer);
                }}
              >
                {isAddMer ? "- Remove Measurements" : "+ Add Measurements"}
              </span>
            </div>
          </div>
          {isAddMer && (
            <>
              {select_array.map((item, index) => {
                return (
                  <div className="col-12 col-md-3 mt-3" key={index}>
                    <TextField
                      fullWidth
                      type="number"
                      id="outlined-basic"
                      variant="outlined"
                      required={item.require}
                      name={item.name}
                      value={inputs[item.name]}
                      onChange={handleChange}
                      label={item.label}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {item.name == "chest"
                              ? userPre?.chest_unit
                              : item.name == "waist"
                              ? userPre?.waist_unit
                              : item.name == "hip"
                              ? userPre?.hip_unit
                              : item.name == "arm"
                              ? userPre?.arm_unit
                              : item.name == "thigh"
                              ? userPre?.thigh_unit
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
          <div className="col-12 mt-3">
            <TextField
              id="outlined-multiline-static"
              label="Write a note"
              fullWidth
              multiline
              rows={3}
              name="note"
              variant="outlined"
              value={inputs.note}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isFormLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddWeight;
