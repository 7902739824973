import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import profileImg from "../../assets/images/profileImg.png";
import R from "../../assets/images/R.jpeg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TimeZones } from "src/utils/constants";
import { EditProfileApi, memberDetail } from "src/DAL/Profile/Profile";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Switch,
  Typography,
} from "@mui/material";
import countryList from "react-select-country-list";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { profilePlaceholder } from "src/assets";

const Input = styled("input")({
  display: "none",
});
TimeZones.sort();
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

function EditProfile(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [checkedPace, setCheckedPace] = useState(true);

  const [memberData, setMemberData] = useState({
    hours: 1,
    minutes: 0,
    seconds: 0,
    distance: "5km",
    gender: "male",
    weight: "",
    height: "",
    age: "",
    activity_level: "sedentary",
    deficit_percentage: true,
    deficit_value: "",
    days_for_deficit: "",
  });
  const [memberVdotData, setMemberVdotDataData] = useState({
    time_interval: 0,
  });
  const [intervalTime, setIntervalTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = React.useState("");
  const [image, setImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const handleChangeDate = (event) => {
    setMemberData((prevState) => {
      return {
        ...prevState,
        dob: event?.$d,
      };
    });
  };

  const handleSelectOther = (event) => {
    setMemberData((prevState) => {
      return {
        ...prevState,
        last_cycle_date: event?.$d,
      };
    });
  };

  const handleChangePace = (event) => {
    setCheckedPace(event.target.checked);
    setMemberVdotDataData((inputs) => ({
      ...inputs,
      ["time_interval"]: 0,
    }));
    setMemberData((inputs) => ({
      ...inputs,
      ["distance"]: "5km",
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getMemberDetail = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      setMemberVdotDataData((inputs) => ({
        ...inputs,
        ["time_interval"]: parseInt(
          result?.member?.vdot?.time_taken
            ? result?.member?.vdot?.time_taken
            : 0
        ),
      }));
      setCheckedPace(result?.member?.pace_calculator);
      setMemberData((inputs) => ({
        ...inputs,
        ...result.member,
        ["distance"]: result?.member?.vdot?.race_type,
      }));
      console.log(result.member, "result.member");
      setTimeZoneValue(result.member.time_zone);
      setCheckedPace(result?.member?.pace_calculator);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const calculateTDEE = (value) => {
    const { gender, weight, height, age, activity_level } = value;

    if (!gender || !weight || !height || !age || !activity_level) {
      return 0;
    }

    let BMR;

    if (gender === "male") {
      BMR = 10 * weight + 6.25 * height - 5 * age + 5;
    } else if (gender === "female") {
      BMR = 10 * weight + 6.25 * height - 5 * age - 161;
    } else {
      BMR = 10 * weight + 6.25 * height - 5 * age - 78;
    }

    const activityMultipliers = {
      sedentary: 1.2,
      lightly: 1.375,
      moderately: 1.55,
      active: 1.725,
      super: 1.9,
    };

    const TDEE = BMR * (activityMultipliers[activity_level] || 1.2);

    return Math.round(TDEE);
  };

  let totalKcal = calculateTDEE(memberData);

  const handleUpdate = async (e) => {
    e.preventDefault();
    var dateString = moment(memberData.dob).format("YYYY-MM-DD");
    let [year, month, day] = [1, 1, 1];

    if (dateString !== "Invalid date") {
      [year, month, day] = dateString.split("-");
      console.log(day, month, year, "seperated year month day");
      if (year < 1900 || year > moment().year()) {
        enqueueSnackbar("Please select a valid year", { variant: "error" });
      } else {
        let VDOT = {
          race_type: memberData?.distance,
          time_taken: checkedPace ? memberVdotData?.time_interval : 0,
        };
        const formData = new FormData();
        var dateString = moment(memberData.dob).format("YYYY-MM-DD");
        var dateString1 = moment(memberData?.last_cycle_date).format(
          "YYYY-MM-DD"
        );
        formData.append("first_name", memberData.first_name);
        formData.append("last_name", memberData.last_name);
        formData.append("street", memberData.street);
        formData.append("city", memberData.city);
        formData.append("zip_code", memberData.zip_code);
        formData.append("state", memberData.state);
        formData.append("country", memberData.country);
        formData.append("time_zone", timeZoneValue);
        // formData.append("facebook_link", memberData.facebook_link);
        // formData.append("website_link", memberData.website_link);
        // formData.append("instagram_link", memberData.instagram_link);
        // formData.append("linkedin_link", memberData.linkedin_link);
        // formData.append("youtube_link", memberData.youtube_link);
        formData.append("member_biography", memberData.member_biography);
        formData.append("contact_number", memberData.contact_number);
        formData.append("pace_calculator", checkedPace);
        formData.append("gender", memberData.gender);
        formData.append("age", memberData.age);
        formData.append("weight", memberData.weight);
        formData.append("height", memberData.height);
        formData.append("activity_level", memberData.activity_level);
        formData.append("deficit_percentage", memberData.deficit_percentage);
        formData.append("deficit_value", memberData.deficit_value || 0);
        formData.append("days_for_deficit", memberData.days_for_deficit || 0);
        formData.append("regular_day_calories", totalKcal);

        formData.append("vdot", JSON.stringify(VDOT));

        // formData.append(
        //   "nineteen_day_plan_currency",
        //   memberData.nineteen_day_plan_currency
        // );
        formData.append("dob", dateString);
        formData.append("last_cycle_date", dateString1);
        if (image) {
          formData.append("profile_image", image);
        }

        if (memberData.days_for_deficit > 30) {
          enqueueSnackbar("Deficit Duration must be between 1-30", {
            variant: "error",
          });
          return;
        }

        if (memberData.deficit_value > 30) {
          enqueueSnackbar("Deficit Percentage must be between 1-30", {
            variant: "error",
          });
          return;
        }
        if (checkedPace && memberVdotData?.time_interval == 0) {
          return enqueueSnackbar(
            "Please enter a value in the Duration field.",
            {
              variant: "error",
            }
          );
        }
        setIsLoading(true);
        console.log(...formData);
        const result = await EditProfileApi(formData);
        if (result.code === 200) {
          localStorage.setItem(`user_data`, JSON.stringify(result?.member));
          localStorage.setItem(`user_data_new`, JSON.stringify(result?.member));
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Please select a valid date", { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMemberData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleChangeVDOT = (updatedValues) => {
    setMemberVdotDataData((prevData) => ({ ...prevData, ...updatedValues }));
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const handleHoursChange = (e) => {
    const hours = parseInt(e.target.value) || 0;
    const minutes = Math.floor((memberVdotData.time_interval % 3600) / 60);
    const seconds = memberVdotData.time_interval % 60;
    updateIntervalTime(hours * 3600 + minutes * 60 + seconds);
  };
  const handleMinutesChange = (e) => {
    let minutes = parseInt(e.target.value) || 0;
    minutes = Math.min(Math.max(minutes, 0), 59);

    // Calculate hours separately to prevent resetting
    const currentHours = Math.floor(memberVdotData.time_interval / 3600);
    const seconds = memberVdotData.time_interval % 60;

    // Update total seconds with new minutes and current hours
    updateIntervalTime(currentHours * 3600 + minutes * 60 + seconds);
  };

  const handleSecondsChange = (e) => {
    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(memberVdotData.time_interval / 60);
    updateIntervalTime(minutes * 60 + seconds);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (totalSeconds) => {
    setIntervalTime(totalSeconds);

    setMemberVdotDataData((prevState) => {
      return {
        ...prevState,
        ["time_interval"]: totalSeconds,
      };
    });
  };

  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const today = new Date();
  const startDate = memberData?.deficit_start_date
    ? new Date(memberData.deficit_start_date)
    : today;

  if (memberData.days_for_deficit > 0 && memberData.days_for_deficit < 31) {
    startDate.setDate(
      startDate.getDate() + parseInt(memberData.days_for_deficit)
    );
  }

  const formattedDate = startDate.toISOString().split("T")[0];

  useEffect(() => {
    getMemberDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container ">
      <form onSubmit={handleUpdate}>
        <div className="mt-4 mb-4 d-flex justify-content-center">
          <div className="cards-edit p-4">
            <div className=" image d-flex flex-column justify-content-center align-items-center">
              <div className="edit-profile-icon">
                <img
                  src={
                    previews
                      ? previews
                      : memberData.profile_image
                      ? s3baseUrl + memberData.profile_image
                      : profilePlaceholder
                  }
                  height="100"
                  width="100"
                />
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="profile_image"
                    onChange={(e) => handleUpload(e)}
                  />
                  <IconButton
                    className="edit-profile-icon-position"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
              <div className="row w-100 mt-3">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="first_name"
                      value={memberData.first_name}
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.last_name}
                      name="last_name"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      readOnly={true}
                      type="email"
                      variant="outlined"
                      fullWidth
                      inputProps={{ readOnly: true }}
                      size="small"
                      value={memberData.email}
                      name="email"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.contact_number}
                      name="contact_number"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mui-date-picker-box">
                  <div className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of birth"
                        name="dob"
                        className="mui-date-picker"
                        value={memberData.dob}
                        format="DD/MM/YYYY"
                        mask="__/__/____"
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => handleChangeDate(e)}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              input: {
                                color: get_root_value("--input-text-color"),
                              },
                            }}
                            required={true}
                            variant="outlined"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="street"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.street}
                      name="street"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="State"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.state}
                      name="state"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Town or City"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.city}
                      name="city"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4 select-box">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Country
                      </InputLabel>
                      <Select
                        className="svg-color"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={memberData.country}
                        name="country"
                        label="Country"
                        MenuProps={{
                          classes: {
                            paper: classes.paper,
                          },
                        }}
                        onChange={handleChange}
                        size="small"
                        sx={{
                          color: get_root_value("--input-text-color"),
                        }}
                      >
                        {options.map((option_value, i) => {
                          return (
                            <MenuItem value={option_value.value}>
                              {option_value.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Area Code"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.zip_code}
                      name="zip_code"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <Autocomplete
                    value={timeZoneValue}
                    onChange={(event, newValue) => {
                      setTimeZoneValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={TimeZones}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          svg: { color: get_root_value("--input-text-color") },
                          input: {
                            color: get_root_value("--input-text-color"),
                          },
                        }}
                        {...params}
                        label="Time Zone *"
                        size="small"
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required size="small">
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="gender"
                      value={memberData.gender}
                      label="Gender"
                      onChange={handleChange}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {memberData.gender === "female" && (
                  <div className="col-12 col-md-6 mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Last Cycle Date"
                        name="last_cycle_date"
                        className="mui-date-picker"
                        value={memberData.last_cycle_date || null}
                        format="DD/MM/YYYY"
                        mask="__/__/____"
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => handleSelectOther(e)}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              input: {
                                color: get_root_value("--input-text-color"),
                              },
                            }}
                            required={true}
                            variant="outlined"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                )}
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required size="small">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Weight
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={memberData?.weight}
                      onChange={handleChange}
                      name="weight"
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">kg</InputAdornment>
                      }
                      label="Amount"
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required size="small">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Height
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={memberData?.height}
                      type="number"
                      onChange={handleChange}
                      name="height"
                      endAdornment={
                        <InputAdornment position="end">cm</InputAdornment>
                      }
                      label="Amount"
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required size="small">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Age
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={memberData?.age}
                      type="number"
                      onChange={handleChange}
                      name="age"
                      endAdornment={
                        <InputAdornment position="end">year</InputAdornment>
                      }
                      label="Age"
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required size="small">
                    <InputLabel id="demo-simple-select-label">
                      Activity Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="activity_level"
                      value={memberData.activity_level}
                      label="Activity Level"
                      onChange={handleChange}
                    >
                      <MenuItem value="sedentary">
                        Sedentary (little/no exercise)
                      </MenuItem>
                      <MenuItem value="lightly">
                        Lightly active (1-3 days/week exercise)
                      </MenuItem>
                      <MenuItem value="moderately">
                        Moderately active (3-5 days/week exercise)
                      </MenuItem>
                      <MenuItem value="active">
                        Very active (6-7 days/week exercise)
                      </MenuItem>
                      <MenuItem value="super">
                        Super active (athlete or hard labor)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <h3 className="mt-3">Calorie Deficit</h3>
                {memberData.deficit_percentage && (
                  <p className="text-muted mb-0">
                    Enter a calorie deficit percentage between 1-30% and set the
                    duration between 1-30 days.
                  </p>
                )}
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required size="small">
                    <InputLabel id="demo-simple-select-label">
                      Is Allowed Calorie Deficit
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="deficit_percentage"
                      value={memberData.deficit_percentage}
                      label="Is Allowed Calorie Deficit"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {memberData.deficit_percentage && (
                  <>
                    <div className="col-12 col-md-6 mt-4">
                      <FormControl fullWidth required size="small">
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Calorie Deficit Percentage
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          value={memberData?.deficit_value}
                          type="number"
                          onChange={handleChange}
                          name="deficit_value"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          label="Calorie Deficit Percentage"
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Calorie Deficit Duration (Days)"
                        variant="outlined"
                        fullWidth
                        type="number"
                        required
                        name="days_for_deficit"
                        onChange={handleChange}
                        value={memberData.days_for_deficit}
                      />
                    </div>
                    {memberData.days_for_deficit > 0 &&
                      memberData.days_for_deficit < 31 && (
                        <div className="col-12 text-end mt-2">
                          <p className="text-end text-muted">
                            Calorie Deficit Period:{" "}
                            {moment(
                              memberData?.deficit_start_date || new Date()
                            ).format("DD-MM-YYYY")}{" "}
                            to {moment(formattedDate).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      )}
                  </>
                )}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-controlled-open-select-label">
                        Currency for 90 Day Tracker
                      </InputLabel>
                      <Select
                        MenuProps={{
                          classes: {
                            paper: classes.paper,
                          },
                        }}
                        className="svg-color"
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={memberData.nineteen_day_plan_currency}
                        name="nineteen_day_plan_currency"
                        label="Currency for 90 Day Tracker"
                        onChange={(e) => handleChange(e)}
                        size="small"
                        sx={{
                          color: get_root_value("--input-text-color"),
                        }}>
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="eur">Euro</MenuItem>
                        <MenuItem value="gbp">Pond</MenuItem>
                        <MenuItem value="usd">Dollar</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> */}

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4 textarea-block">
                    <TextField
                      id="outlined-basic"
                      label="Biography"
                      variant="outlined"
                      rows={4}
                      multiline
                      fullWidth
                      size="small"
                      value={memberData.member_biography}
                      name="member_biography"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 text-end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedPace}
                        onChange={handleChangePace}
                      />
                    }
                    label="Pace Calculator"
                  />
                  {/* <Switch
                    checked={checkedPace}
                    onChange={handleChangePace}
                    inputProps={{ "aria-label": "controlled" }}
                  /> */}
                </div>
                {checkedPace && (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="mt-4">
                        <h3>Pace Calculator</h3>
                        <Typography sx={{ fontSize: 12, opacity: 0.8 }}>
                          Enter results to calculate the pace.
                        </Typography>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mt-4 select-box">
                        <FormControl size="small" fullWidth variant="outlined">
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              color: get_root_value("--input-text-color"),
                            }}
                          >
                            Distance
                          </InputLabel>
                          <Select
                            className="svg-color"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={memberData.distance}
                            name="distance"
                            label="Distance"
                            MenuProps={{
                              classes: {
                                paper: classes.paper,
                              },
                            }}
                            onChange={handleChange}
                            size="small"
                            sx={{
                              color: get_root_value("--input-text-color"),
                            }}
                          >
                            <MenuItem value="5km">5 (km)</MenuItem>
                            <MenuItem value="10km">10 (km)</MenuItem>
                            <MenuItem value="15km">15 (km)</MenuItem>
                            <MenuItem value="half_marathon">
                              Half Marathon
                            </MenuItem>
                            <MenuItem value="marathon">Marathon</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mt-4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          label="Duration (hh:mm:ss)"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            // style: {
                            //   fontSize: "13px",
                            //   paddingLeft: "0px",
                            //   paddingRight: "0px",
                            //   justifyContent: "center",
                            // },
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  id="outlined-basic-hours"
                                  type="number"
                                  size="small"
                                  placeholder="Hrs"
                                  value={formatWithLeadingZeros(
                                    Math.floor(
                                      memberVdotData.time_interval / 3600
                                    )
                                  )}
                                  onChange={handleHoursChange}
                                  InputProps={
                                    {
                                      // style: {
                                      //   fontSize: "19px",
                                      //   fontWeight: "500",
                                      // },
                                    }
                                  }
                                  sx={{
                                    "& fieldset": {
                                      border: "none",
                                    },
                                    "& input": {
                                      textAlign: "center",
                                      padding: "0px",
                                    },
                                    width: "20%",
                                  }}
                                />
                                <div className="fs-4 fw-semibold ">:</div>

                                <TextField
                                  id="outlined-basic-minutes"
                                  type="number"
                                  size="small"
                                  placeholder="Min"
                                  value={formatWithLeadingZeros(
                                    Math.floor(
                                      (memberVdotData.time_interval % 3600) / 60
                                    )
                                  )}
                                  onChange={handleMinutesChange}
                                  InputProps={
                                    {
                                      // style: {
                                      //   fontSize: "19px",
                                      //   fontWeight: "500",
                                      // },
                                    }
                                  }
                                  sx={{
                                    "& fieldset": {
                                      border: "none",
                                    },
                                    "& input": {
                                      textAlign: "center",
                                      padding: "0px",
                                    },
                                    width: "20%",
                                  }}
                                />
                                <div className="fs-4 fw-semibold ">:</div>

                                <TextField
                                  id="outlined-basic-seconds"
                                  type="number"
                                  size="small"
                                  placeholder="Sec"
                                  value={formatWithLeadingZeros(
                                    memberVdotData.time_interval % 60
                                  )}
                                  onChange={handleSecondsChange}
                                  InputProps={
                                    {
                                      // style: {
                                      //   fontSize: "19px",
                                      //   fontWeight: "500",
                                      // },
                                    }
                                  }
                                  sx={{
                                    "& fieldset": {
                                      border: "none",
                                    },
                                    "& input": {
                                      textAlign: "center",
                                      padding: "0px",
                                    },
                                    width: "20%",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            borderRadius: "5px",
                            fontSize: "13px",
                            width: "100%", // Adjust the width as needed
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Website Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.website_link}
                      name="website_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Instagram Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.instagram_link}
                      name="instagram_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Youtube Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.youtube_link}
                      name="youtube_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div> */}

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-3">
                <span className="upload-button mt-3">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file" className="w-100">
                    <CloudUploadIcon />
                  </label>
                </span>
              </div>
            </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-3">
                {previews &&
                  previews.map((file, index) => (
                    <div className="col-3 mt-3" key={index}>
                      <div className="preview">
                        <span onClick={() => handleRemove(index)}>x</span>
                        <img src={file} />
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
              </div>
              <div className="mt-2 ms-auto">
                <button className="small-contained-button mt-4 ">Update</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
