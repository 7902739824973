import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { get_root_value } from "src/utils/domUtils";

const PaceCalculator = ({
  checkedPace,
  memberVdotData,
  setMemberVdotDataData,
  setIsShow,
  isShow,
  handleUpdate,
  isFormLoading,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setMemberVdotDataData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setIsShow(true);
  };

  const handleChangeOther = (event) => {
    const { name, value } = event.target;
    setMemberVdotDataData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const getPaceText = (type) => {
    let result = "";
    if (type?.includes("mi")) {
      result = "Pace Min/Mile ";
    } else if (type?.includes("km")) {
      result = "Pace Min/Km";
    } else {
      result = "Time Cap";
    }
    return result;
  };

  const handleChangeVdotDistance = (name) => {
    let optionsTypeSArray = [];
    switch (name) {
      case "i":
        optionsTypeSArray = [
          { title: "400 Meters Pace", key: "i_400_m" },
          { title: "1 KM Pace", key: "i_km" },
          { title: "1 MILE Pace", key: "i_mi" },
        ];
        break;

      case "t":
        optionsTypeSArray = [
          { title: "1 KM Pace", key: "t_km" },
          { title: "1 MILE Pace", key: "t_mi" },
        ];
        break;

      default:
        optionsTypeSArray = [
          { title: "1 KM Pace", key: "e_km" },
          { title: "1 MILE Pace", key: "e_mi" },
        ];
    }

    if (optionsTypeSArray.length > 0) {
      return optionsTypeSArray;
    }
  };

  const handleHoursChange = (e) => {
    const hours = parseInt(e.target.value) || 0;
    const minutes = Math.floor((memberVdotData.time_interval % 3600) / 60);
    const seconds = memberVdotData.time_interval % 60;
    updateIntervalTime(hours * 3600 + minutes * 60 + seconds);
  };

  const handleMinutesChange = (e) => {
    let minutes = parseInt(e.target.value) || 0;
    minutes = Math.min(Math.max(minutes, 0), 59);
    const currentHours = Math.floor(memberVdotData.time_interval / 3600);
    const seconds = memberVdotData.time_interval % 60;
    updateIntervalTime(currentHours * 3600 + minutes * 60 + seconds);
  };

  const handleSecondsChange = (e) => {
    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59);
    const minutes = Math.floor(memberVdotData.time_interval / 60);
    updateIntervalTime(minutes * 60 + seconds);
  };

  const updateIntervalTime = (totalSeconds) => {
    setMemberVdotDataData((prevState) => {
      return {
        ...prevState,
        ["time_interval"]: totalSeconds,
      };
    });
    setIsShow(true);
  };

  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  let is_allow =
    memberVdotData?.pace ||
    memberVdotData?.paceUp ||
    memberVdotData?.pace1 ||
    memberVdotData?.pace2;

  return (
    <>
      <div className="row">
        {checkedPace && (
          <>
            <div className="col-12 col-md-6 mt-3">
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  Distance
                </InputLabel>
                <Select
                  className="svg-color"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={memberVdotData.distance || ""}
                  name="distance"
                  label="Distance"
                  onChange={handleChange}
                  size="small"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  <MenuItem value="5km">5 (km)</MenuItem>
                  <MenuItem value="10km">10 (km)</MenuItem>
                  <MenuItem value="15km">15 (km)</MenuItem>
                  <MenuItem value="half_marathon">Half Marathon</MenuItem>
                  <MenuItem value="marathon">Marathon</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Duration (hh:mm:ss)"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id="outlined-basic-hours"
                        type="number"
                        size="small"
                        placeholder="Hrs"
                        value={formatWithLeadingZeros(
                          Math.floor(memberVdotData.time_interval / 3600)
                        )}
                        onChange={handleHoursChange}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "20%",
                        }}
                      />
                      <div className="fs-5 fw-semibold">:</div>
                      <TextField
                        id="outlined-basic-minutes"
                        type="number"
                        size="small"
                        placeholder="Min"
                        value={formatWithLeadingZeros(
                          Math.floor((memberVdotData.time_interval % 3600) / 60)
                        )}
                        onChange={handleMinutesChange}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "20%",
                        }}
                      />
                      <div className="fs-5 fw-semibold ">:</div>
                      <TextField
                        id="outlined-basic-seconds"
                        type="number"
                        size="small"
                        placeholder="Sec"
                        value={formatWithLeadingZeros(
                          memberVdotData.time_interval % 60
                        )}
                        onChange={handleSecondsChange}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "20%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "5px",
                  fontSize: "13px",
                  width: "100%",
                }}
              />
            </div>
          </>
        )}
        {isShow && (
          <div className="col-12 text-end">
            <button
              onClick={(e) => {
                handleUpdate(e);
              }}
              disabled={isFormLoading}
              className="mt-3 ms-auto shopping_btn"
            >
              {isFormLoading ? "Loading..." : "Update"}
            </button>
          </div>
        )}
        {checkedPace && is_allow ? (
          <>
            <div className="col-12 mt-2">
              <p className="mb-0">Your Calculated Pace:</p>
            </div>

            <div className="col-12 col-md-6 mt-3">
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  Easy/Long
                </InputLabel>
                <Select
                  className="svg-color"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={memberVdotData.easy_long || ""}
                  name="easy_long"
                  label="Easy/Long"
                  onChange={handleChangeOther}
                  size="small"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  {handleChangeVdotDistance()?.map((option) => {
                    return (
                      <MenuItem key={option?.key} value={option?.key}>
                        {option?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label={getPaceText(memberVdotData?.easy_long)}
                InputLabelProps={{
                  className: "textfiels-input-label",
                  shrink: true,
                  style: { fontSize: "14px" },
                }}
                InputProps={{
                  className: "textfiels-input-value",
                  style: {
                    fontSize: "13px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  },

                  readOnly: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id="outlined-basic-minutes"
                        type="number"
                        size="small"
                        placeholder="Min"
                        name="pace"
                        disabled
                        value={formatWithLeadingZeros(
                          Math.floor(memberVdotData?.pace / 60)
                        )}
                        readOnly={true}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                        }}
                      />
                      <div className="fs-6 fw-semibold ms-1 ">:</div>
                      <TextField
                        id="outlined-basic-seconds"
                        type="number"
                        size="small"
                        placeholder="Sec"
                        name="pace"
                        disabled
                        value={formatWithLeadingZeros(
                          memberVdotData?.pace % 60
                        )}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                        }}
                      />
                      <div className="fs-6 fw-semibold ms-1 me-1 ">-</div>
                      <TextField
                        id="outlined-basic-minutes"
                        type="number"
                        size="small"
                        placeholder="Min"
                        name="paceUp"
                        disabled
                        value={formatWithLeadingZeros(
                          Math.floor(memberVdotData?.paceUp / 60)
                        )}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                        }}
                      />
                      <div className="fs-6 fw-semibold ms-1 ">:</div>
                      <TextField
                        id="outlined-basic-seconds"
                        type="number"
                        size="small"
                        disabled
                        placeholder="Sec"
                        name="paceUp"
                        value={formatWithLeadingZeros(
                          memberVdotData?.paceUp % 60
                        )}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                          marginRight: 1,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "5px",
                  fontSize: "13px",
                  "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input": {
                    width: "0px !important",
                    padding: "9px 0px !important",
                  },
                  "& .css-1nuss9t": {
                    width: "0px !important",
                    padding: "9px 0px !important",
                  },
                  width: "100%",
                }}
              />
            </div>

            <div className="col-12 col-md-6 mt-3">
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  Threshold
                </InputLabel>
                <Select
                  className="svg-color"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={memberVdotData.thre_shold || ""}
                  name="thre_shold"
                  label="Threshold"
                  onChange={handleChangeOther}
                  size="small"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  {handleChangeVdotDistance("t")?.map((option) => {
                    return (
                      <MenuItem key={option?.key} value={option?.key}>
                        {option?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label={getPaceText(memberVdotData?.thre_shold)}
                InputLabelProps={{
                  className: "textfiels-input-label",
                  shrink: true,
                  style: { fontSize: "14px" },
                }}
                InputProps={{
                  className: "textfiels-input-value",
                  style: {
                    fontSize: "13px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  },
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id="outlined-basic-minutes"
                        type="number"
                        size="small"
                        placeholder="Min"
                        disabled
                        name="pace"
                        value={formatWithLeadingZeros(
                          Math.floor(memberVdotData?.pace1 / 60)
                        )}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                        }}
                      />
                      <div className="fs-6 fw-semibold ms-1 ">:</div>

                      <TextField
                        id="outlined-basic-seconds"
                        type="number"
                        size="small"
                        placeholder="Sec"
                        name="pace"
                        disabled
                        value={formatWithLeadingZeros(
                          memberVdotData?.pace1 % 60
                        )}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },

                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "5px",
                  fontSize: "13px",
                  "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input": {
                    width: "0px !important",
                    padding: "9px 0px !important",
                  },
                  "& .css-1nuss9t": {
                    width: "0px !important",
                    padding: "9px 0px !important",
                  },
                  width: "100%",
                }}
              />
            </div>

            <div className="col-12 col-md-6 mt-3">
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  Interval
                </InputLabel>
                <Select
                  className="svg-color"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={memberVdotData.interval || ""}
                  name="interval"
                  label="Interval"
                  onChange={handleChangeOther}
                  size="small"
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  {handleChangeVdotDistance("i")?.map((option) => {
                    return (
                      <MenuItem key={option?.key} value={option?.key}>
                        {option?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label={getPaceText(memberVdotData?.interval)}
                InputLabelProps={{
                  className: "textfiels-input-label",
                  shrink: true,
                  style: { fontSize: "14px" },
                }}
                InputProps={{
                  className: "textfiels-input-value",
                  style: {
                    fontSize: "13px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  },
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id="outlined-basic-minutes"
                        type="number"
                        size="small"
                        placeholder="Min"
                        name="pace"
                        disabled
                        value={formatWithLeadingZeros(
                          Math.floor(memberVdotData?.pace2 / 60)
                        )}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                        }}
                      />
                      <div className="fs-6 fw-semibold ms-1 ">:</div>
                      <TextField
                        id="outlined-basic-seconds"
                        type="number"
                        size="small"
                        placeholder="Sec"
                        name="pace"
                        disabled
                        value={formatWithLeadingZeros(
                          memberVdotData?.pace2 % 60
                        )}
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "14px",
                          },
                          readOnly: true,
                        }}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },

                          "& input": {
                            textAlign: "center",
                            padding: "0px",
                          },
                          width: "100%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "5px",
                  fontSize: "13px",
                  "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input": {
                    width: "0px !important",
                    padding: "9px 0px !important",
                  },
                  "& .css-1nuss9t": {
                    width: "0px !important",
                    padding: "9px 0px !important",
                  },
                  width: "100%",
                }}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PaceCalculator;
