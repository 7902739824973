import { styled } from "@mui/material/styles";
import { Card, Typography, Link, Tooltip } from "@mui/material";
import Iconify from "src/components/Iconify";
import { Link as RouterLink } from "react-router-dom";

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  width: theme.spacing(8),
  height: theme.spacing(8),
  background:
    "linear-gradient(145deg, rgba(4,171,187,0.3), rgba(2,102,112,0.3))",
  backdropFilter: "blur(12px)",
  boxShadow: `
  inset 4px 4px 8px rgba(0, 0, 0, 0.2),
  inset -4px -4px 8px rgba(255, 255, 255, 0.1),
  0 10px 20px rgba(0, 0, 0, 0.15)
`,
}));

export default function SummaryCard({ item, sx, ...other }) {
  return (
    <Card
      sx={{
        textAlign: { xs: "center", md: "left" },
        boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.12)",
        background: "linear-gradient(145deg, #f8f9fa, #ffffff)",
        borderRadius: "15px",
        padding: "0px 30px 0px 30px;",
        height: 110,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        transition: "transform 0.3s ease-in-out",
        ...sx,
      }}
      {...other}
    >
      <div className="row align-items-center">
        <div className="col-3">
          <IconWrapperStyle>
            <Iconify
              icon={item?.icon}
              width={30}
              height={30}
              sx={{ color: "#026670" }}
            />
          </IconWrapperStyle>
        </div>{" "}
        <div className="col-9 ps-4">
          <Typography
            sx={{ fontWeight: "bold", color: "#333", fontSize: "20px" }}
          >
            {typeof item?.count === "string"
              ? item?.count
              : Math.round(item?.count * 10) / 10}
          </Typography>
          <Tooltip title={item.tooltip} placement="top">
            <Typography
              variant="subtitle1"
              sx={{ opacity: 0.8, color: "#666" }}
            >
              {item?.title}
            </Typography>
          </Tooltip>
          {item?.link && (
            <Link
              sx={{
                color: "#026670",
                textDecoration: "none",
                fontWeight: "bold",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              component={RouterLink}
              variant="subtitle2"
              to={`${item?.to}`}
            >
              {item?.link_text ? item?.link_text : "View History →"}
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
}
