import React, { useState, useEffect } from "react";
// import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";

import "@fullcalendar/daygrid/main.css";
import moment from "moment";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import {
  programmeDetailApi,
  programme_workout_list_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import { memberDetail, memberRewardDetail } from "src/DAL/Profile/Profile";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  // console.log(startOfMonth, "asfjlks");
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
function ProgramCalender(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [workouts, setworkouts] = useState([]);
  const navigate = useNavigate();
  const { slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [reorderWorkouts, setReorderWorkouts] = useState([]);
  const classes = useStyles();
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const [workoutDays, setWorkoutDays] = useState();
  const [selectedTool, setSelectedTool] = useState("month");
  const [submitted, setSubmitted] = useState(false);
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [memberData, setMemberData] = useState({});
  const [memberRewardData, setMemberRewardData] = useState({});
  console.log(workoutDays, "workoutDaysworkoutDaysworkoutDays");
  const getEventListing = async (dateValue) => {
    if (selectedTool !== "month") {
      setIsLoading(true);
    }
    console.log(state, "sdkfkjasdlk");

    const newCurrentDate = moment(); // Get today's date
    let postData = {};
    if (dateValue && dateValue >= 0) {
      //uncomment this to send specific dates of duration
      postData = {
        // start_date: newCurrentDate.format("YYYY-MM-DD"), // Format today's date
        start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
        end_date: newCurrentDate
          .add(dateValue - 1, "days")
          .format("YYYY-MM-DD"), // Add 2 days to today's date and format it
        slug: state?._id?._id || state?.program_info?.program_id,
      };
      // postData = {
      //   start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      //   end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
      //   // slug: slug,
      //   slug: state?._id?._id,
      // };
    } else {
      postData = {
        start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
        end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
        // slug: slug,
        slug: state?._id?._id || state?.program_info?.program_id,
      };
    }

    console.log(postData, "========post data ======");
    const result = await programme_workout_list_api(postData);
    if (result.code === 200) {
      let all_events = [];
      setDefaultTimeZone("asia/karachi");
      console.log(result, "dsafjsdjkaf");
      const mergedData = [
        ...result?.workouts,
        ...result?.program_workouts.filter(
          (item) =>
            !result?.workouts.find(
              (existingItem) => existingItem.workout_id === item._id
            )
        ),
      ];

      console.log(mergedData, "=======mergedData at start of calender");
      setSubmitted(false);
      setworkouts(mergedData);
      setReorderWorkouts(result?.reordered_workouts);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getMemberDetail = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      setMemberData(result.member);

      console.log(result.member, "result.member");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getMemberRewardDetail = async () => {
    const result = await memberRewardDetail();
    if (result.code === 200) {
      setMemberRewardData(result?.reward_points);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getProgrammeDetail = async () => {
    if (selectedTool !== "month") {
      setIsLoading(true);
    }
    const result = await programmeDetailApi(
      state?._id?._id || state?.program_info?.program_id
    );
    if (result.code === 200) {
      console.log(result, "programmeDetail");
      if (result.program.is_workout_visible_days) {
        setWorkoutDays(
          result.program.workout_visible_time_period
            ? result.program.workout_visible_time_period
            : 1
        );
        getEventListing(result.program.workout_visible_time_period);
      } else {
        getEventListing();
      }

      // setIsLoading(false);
    } else {
      getEventListing();
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getMemberDetail();
  }, []);
  useEffect(() => {
    getMemberRewardDetail();
    getProgrammeDetail();
  }, [currentDate, submitted]);

  const content_setting = "";
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <GeneralCalendar
        programWorkoutList={workouts}
        reorderedWorkoutList={reorderWorkouts}
        setCurrentDate={setCurrentDate}
        showAddEvent={true}
        time_zone={defaultTimeZone}
        currentDate={currentDate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        reloadList={getProgrammeDetail}
        // reloadList={getEventListing}
        calendar_title={state?.program_info?.title || state?.title}
        programme={state}
        member_vdot={
          memberData?.pace_calculator ? memberData?.vdot?.vdot_value : null
        }
        memberRewardData={memberRewardData}
        setSubmitted={setSubmitted}
        submitted={submitted}
      />
    </>
  );
}

export default ProgramCalender;
