import {
  CircularProgress,
  TextField,
  Tooltip,
  Box,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import CongratulationAnimation from "./component/CongratulationAnimation";

import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import ConfirmationWithDescription from "src/components/ConfirmationWithDescription";
import { ReactVideoPlayer } from "src/components";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const VideoUI = ({
  inputs,
  setInputs,
  addArray,
  setaddArray,
  setModalChangeExe,
  isWorkoutCompleted,
  setIsWorkoutCompleted,
  practiceType,
  memberRewardData,
  handleSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [congratulation, setCongratulation] = useState(false);
  const [openExerciseAlert, setOpenExerciseAlert] = useState(false);
  const [exerIndex, setExerIndex] = useState("");

  const classes = useStyles();

  // Function to update intervalTime state
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleChangeComplete = (e) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    if (!checked) {
      setOpenExerciseAlert(true);
      return;
    }
    setInputs({
      ...inputs,
      ["is_completed"]: checked,
    });
    if (checked) {
      handleSubmit(true);
      // setCongratulation(true);
    }
  };
  const handleAgreeInExerciseSet = () => {
    setIsWorkoutCompleted(false);
    setModalChangeExe(true);
    setOpenExerciseAlert(false);
    setInputs({
      ...inputs,
      ["is_completed"]: false,
    });
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (congratulation) {
  //       setCongratulation(false);
  //     }
  //   }, 2500);

  //   return () => clearTimeout(timeout); // Clean up the timeout to avoid memory leaks
  // }, [congratulation]);
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(
    memberRewardData[practiceType],
    "sdfklsjdfjlkja",
    practiceType,
    memberRewardData
  );
  return (
    <div className="my-2">
      <ConfirmationWithDescription
        open={openExerciseAlert}
        setOpen={setOpenExerciseAlert}
        title={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "Opps!" :
          "Are you sure you want to take this action?"
        }
        // descriotion={
        //   memberRewardData?.total_points - memberRewardData[practiceType] < 0
        //     ? "You are not able to uncomplete because you have insufficient points/coins."
        //     : `If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`
        // }
        descriotion={`If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`}
        handleAgree={handleAgreeInExerciseSet}
        isJustForOk={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? true :
          false
        }
      />
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      {/* {congratulation && <CongratulationAnimation />} */}
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-8 ">
              <ReactVideoPlayer url={inputs.video_url} />
              <div className="d-flex justify-content-end mt-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ p: "3px" }}
                        checked={
                          inputs?.is_completed ? inputs?.is_completed : false
                        }
                      />
                    }
                    onChange={(e) => handleChangeComplete(e)}
                    //  mark as complete of video only program and general program video workout
                    label="Mark as Complete"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoUI;
