import { invokeApi } from "../../bl_libs/invokeApi";

export const memberDetail = async (id) => {
  const requestObj = {
    path: `api/member/profile?program_id=${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_shoping_list_api = async (data) => {
  const requestObj = {
    path: `api/program_workout/generate_shopping_list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const memberRewardDetail = async () => {
  const requestObj = {
    path: `api/member/get_member_default_reward_points/by_member`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const dashboardDetail = async () => {
  const requestObj = {
    path: `api/dashboard/dashboard_for_client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const ProjectInfoApi = async () => {
  const requestObj = {
    path: `api/member_init`,
    method: "GET",
    headers: {
      // "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const CoinInfoApi = async (page, limt, data) => {
  const requestObj = {
    path: `api/reward_point/reward_points_list_for_member?page=${page}&limit=${limt}&points_type=${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditProfileApi = async (data) => {
  const requestObj = {
    path: `api/member/edit_member_profile`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditProfileApiDashBoard = async (data, view) => {
  const requestObj = {
    path: `api/member/edit_member_profile?view=${view}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const subscriptionsDetailApi = async (id) => {
  const requestObj = {
    path: `api/member/subscription/details/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getSubscriptionsListApi = async (data) => {
  const requestObj = {
    path: `api/member/subscription_list/client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const cancelSubscriptionsListApi = async (id) => {
  const requestObj = {
    path: `api/member/cancel/subscription/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
