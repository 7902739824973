import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Icon } from "@iconify/react";
import { Box, Typography, IconButton } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";
import { is_small_medium_screen } from "src/utils/constants";

export default function CustomPopoverNew({
  isOpenPop,
  is_hide_icon,
  isClosePop = () => {},
  title,
  componentToPassDown,
  heigh,
  width,
}) {
  const handleClose = () => {
    isClosePop(false);
  };

  return (
    <Dialog
      maxWidth="md"
      sx={{
        "@media (max-width: 768px)": {
          ml: 0,
        },
      }}
      open={isOpenPop}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "#ffffff",
          color: "black",
          boxShadow: "none",
          minWidth: is_small_medium_screen() ? "100%" : width ? width : "950px",
          minHeight: heigh ? heigh : "auto",
        },
      }}
    >
      <Box>
        <div className="custom-modal mt-2 px-4 pt-3">
          <div>
            <Typography
              variant="h5"
              component="h2"
              sx={{ color: "var(--portal-theme-primary)" }}
            >
              {title}
            </Typography>
          </div>
          {!is_hide_icon && (
            <div>
              <IconButton onClick={handleClose} className="icon-button-modal">
                <Icon
                  icon={closeFill}
                  width={20}
                  height={20}
                  style={{ fill: "var(--portal-theme-primary)" }}
                />
              </IconButton>
            </div>
          )}
        </div>
        <div>{componentToPassDown}</div>
      </Box>
    </Dialog>
  );
}
