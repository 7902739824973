import React from "react";
import { Chip, Tooltip, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import { useNavigate } from "react-router-dom";

const WorkOutList = ({ item, extra }) => {
  const navigate = useNavigate();

  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const getMinutesForInterval = (mainindex, index) => {
    const list = [...item.exercises];
    const interval = list[mainindex].interval_duration;
    if (interval <= 60) {
      return index + 1;
    }
    if (interval % 60 == 0) {
      return `${
        (interval / 60) * index +
        1 +
        " – " +
        ((interval / 60) * index + interval / 60)
      }`;
    }
    let startInterval = interval * index;
    let endtInterval = interval * index + interval;
    let startintervalText =
      interval * index
        ? Math.floor(startInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(startInterval % 60)
        : 0;
    let endIntervalText =
      Math.floor(endtInterval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(endtInterval % 60);

    return `${startintervalText + " – " + endIntervalText}`;
  };

  return (
    <div
      className="calendar-workout-card"
      id={item?._id}
      onClick={() => {
        navigate(`/programmes-calendar/${item?.program_info?.program_slug}`, {
          state: {
            ...item,
            workout_title: item.title,
            open_dashboard: true,
          },
        });
      }}
    >
      <div
        className="calendar-workout-card-title"
        style={{
          backgroundColor: item?.program_info?.color,
        }}
      >
        <div className="calendar-workout-card-program d-flex justify-content-between">
          <div> {item?.program_info?.title}</div>
          {item?.workout_completed && (
            <div className="">
              <Icon
                icon="fluent-mdl2:completed-solid"
                className="workout-complete-icon-container "
              />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <div className="calendar-workout-card-workout">{item.title}</div>
          <div className="d-flex align-items-center">
            <Icon
              icon={
                item.practice_type == "workout"
                  ? "mdi:gym"
                  : item.practice_type == "food"
                  ? "fluent:food-24-filled"
                  : item.practice_type == "mindset"
                  ? "flowbite:brain-outline"
                  : item.practice_type == "video"
                  ? "lets-icons:video-fill"
                  : "mdi:gym"
              }
              className="workout-type-icon-container"
            />
          </div>
        </div>
      </div>
      <div className="calendar-workout-card-exercises">
        <div className="calendar-workout-card-container py-1  ">
          {item.practice_type == "video" ? (
            <>
              <div className="inner-div workout-calendar-exercise-card my-1">
                {item?.dumyImage ? (
                  <div
                    style={{
                      whiteSpace: "break-spaces",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      className="image-border"
                      src={item?.dumyImage}
                      height="50"
                    />
                    <div className="overlay-icon">
                      <Icon
                        icon={"lets-icons:video-fill"}
                        className="workout-type-icon-container"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      whiteSpace: "break-spaces",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      className="image-border"
                      src={s3baseUrl + item?.video_thumbnail?.thumbnail_1}
                      height="50"
                    />
                    <div className="overlay-icon">
                      <Icon
                        icon={"lets-icons:video-fill"}
                        className="workout-type-icon-container"
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {item?.exercises?.map((exercise, index) => {
            if (!exercise.is_extra) {
              if (exercise?.exercise_type == "something_else") {
                return (
                  <div
                    className="inner-div workout-calendar-exercise-card my-1 "
                    style={{ position: "relative" }}
                  >
                    {" "}
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                      }}
                      className="py-1"
                    >
                      {exercise?.is_modified && (
                        <Tooltip
                          title={
                            exercise?.is_modified ? "Modified" : "Not Modified"
                          }
                        >
                          <span
                            style={{
                              display: "block",
                              position: "absolute",
                              top: "-5px",
                              justifyContent: "center",
                              zIndex: "1",
                              right: "0px",
                            }}
                          >
                            <Chip
                              label={"Modified"}
                              variant="contained"
                              color={"error"}
                              sx={{
                                fontSize: "6px",
                                height: "auto",
                                width: "auto",
                                borderRadius: "3px",
                                "& .MuiChip-label": {
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                },
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {exercise?.is_completed && (
                        <Tooltip
                          title={
                            exercise?.is_completed
                              ? "Completed"
                              : "Not Completed"
                          }
                        >
                          <Icon
                            icon="fluent-mdl2:completed-solid"
                            className="calendar-workout-card-complete-exercises-icon mb-1"
                          />
                        </Tooltip>
                      )}

                      {exercise?.title}
                    </div>
                  </div>
                );
              } else if (
                exercise?.exercise_type == "amrap" &&
                exercise?.emom_amrap &&
                exercise?.emom_amrap.length > 0
              ) {
                return (
                  <div className="workout-calendar-Superset-card inner-div my-1">
                    <div className="text-center">
                      {" "}
                      {exercise?.is_completed && (
                        <Tooltip
                          title={
                            exercise?.is_completed
                              ? "Completed"
                              : "Not Completed"
                          }
                        >
                          <Icon
                            icon="fluent-mdl2:completed-solid"
                            className="calendar-workout-card-complete-exercises-icon mb-1"
                          />
                        </Tooltip>
                      )}
                      AMRAP
                    </div>
                    {exercise?.emom_amrap?.map((interval, intervalIndex) => {
                      return (
                        <div className="my-1">
                          <div style={{ fontSize: "8px" }}>Movements</div>
                          {interval?.map((exerData) => {
                            return (
                              <div
                                className="inner-div"
                                style={{
                                  whiteSpace: "break-spaces",
                                  marginBottom: "3px",
                                }}
                              >
                                {exerData?.exercise_info?.title}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              } else if (
                exercise?.exercise_type == "rounds_for_time" &&
                exercise?.emom_amrap &&
                exercise?.emom_amrap.length > 0
              ) {
                return (
                  <div className="workout-calendar-Superset-card inner-div my-1">
                    <div className="text-center">
                      {" "}
                      {exercise?.is_completed && (
                        <Tooltip
                          title={
                            exercise?.is_completed
                              ? "Completed"
                              : "Not Completed"
                          }
                        >
                          <Icon
                            icon="fluent-mdl2:completed-solid"
                            className="calendar-workout-card-complete-exercises-icon mb-1"
                          />
                        </Tooltip>
                      )}
                      Rounds For Time
                    </div>
                    {exercise?.emom_amrap?.map((interval, intervalIndex) => {
                      return (
                        <div className="my-1">
                          <div style={{ fontSize: "8px" }}>Movements</div>
                          {interval?.map((exerData) => {
                            return (
                              <div
                                className="inner-div"
                                style={{
                                  whiteSpace: "break-spaces",
                                  marginBottom: "3px",
                                }}
                              >
                                {exerData?.exercise_info?.title}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              } else if (
                exercise?.exercise_type == "reps_for_time" &&
                exercise?.emom_amrap &&
                exercise?.emom_amrap.length > 0
              ) {
                return (
                  <div className="workout-calendar-Superset-card inner-div my-1">
                    <div className="text-center">
                      {" "}
                      {exercise?.is_completed && (
                        <Tooltip
                          title={
                            exercise?.is_completed
                              ? "Completed"
                              : "Not Completed"
                          }
                        >
                          <Icon
                            icon="fluent-mdl2:completed-solid"
                            className="calendar-workout-card-complete-exercises-icon mb-1"
                          />
                        </Tooltip>
                      )}
                      Reps For Time
                    </div>
                    {exercise?.emom_amrap?.map((interval, intervalIndex) => {
                      return (
                        <div className="my-1">
                          <div style={{ fontSize: "8px" }}>Movements</div>
                          {interval?.map((exerData) => {
                            return (
                              <div
                                className="inner-div"
                                style={{
                                  whiteSpace: "break-spaces",
                                  marginBottom: "3px",
                                }}
                              >
                                {exerData?.exercise_info?.title}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              } else if (
                exercise?.exercise_type == "emom" &&
                exercise?.emom_amrap &&
                exercise?.emom_amrap.length > 0
              ) {
                return (
                  <div className="workout-calendar-Superset-card inner-div my-1">
                    <div className="text-center">
                      {" "}
                      {exercise?.is_completed && (
                        <Tooltip
                          title={
                            exercise?.is_completed
                              ? "Completed"
                              : "Not Completed"
                          }
                        >
                          <Icon
                            icon="fluent-mdl2:completed-solid"
                            className="calendar-workout-card-complete-exercises-icon mb-1"
                          />
                        </Tooltip>
                      )}{" "}
                      EMOM
                    </div>
                    {exercise?.emom_amrap?.map((interval, intervalIndex) => {
                      return (
                        <div className="my-1">
                          <div style={{ fontSize: "8px" }}>
                            Minute {getMinutesForInterval(index, intervalIndex)}
                          </div>
                          {interval?.map((exerData) => {
                            return (
                              <div
                                className="inner-div"
                                style={{
                                  whiteSpace: "break-spaces",
                                  marginBottom: "3px",
                                }}
                              >
                                {exerData?.exercise_info?.title}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              } else if (
                exercise?.sub_exercises &&
                exercise?.sub_exercises.length > 0
              ) {
                return (
                  <div className="workout-calendar-Superset-card inner-div my-1">
                    {exercise?.sub_exercises?.map((exerData, exerIndex) => {
                      return (
                        <div style={{ position: "relative" }} className="my-2">
                          <div
                            className="inner-div"
                            style={{
                              whiteSpace: "break-spaces",
                              marginBottom: "3px",
                            }}
                          >
                            {exerData?.is_modified && (
                              <Tooltip
                                title={
                                  exerData?.is_modified
                                    ? "Modified"
                                    : "Not Modified"
                                }
                              >
                                <span
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top: "-12px",
                                    justifyContent: "center",
                                    zIndex: "1",
                                    right: "0px",
                                  }}
                                >
                                  <Chip
                                    label={"Modified"}
                                    variant="contained"
                                    color={"error"}
                                    sx={{
                                      fontSize: "6px",
                                      height: "auto",
                                      width: "auto",
                                      borderRadius: "3px",
                                      "& .MuiChip-label": {
                                        paddingLeft: "3px",
                                        paddingRight: "3px",
                                      },
                                    }}
                                  />
                                </span>
                              </Tooltip>
                            )}
                            {exerData?.is_completed && (
                              <Tooltip
                                title={
                                  exerData?.is_completed
                                    ? "Completed"
                                    : "Not Completed"
                                }
                              >
                                <Icon
                                  icon="fluent-mdl2:completed-solid"
                                  className="calendar-workout-card-complete-exercises-icon"
                                />
                              </Tooltip>
                            )}

                            {exerData?.exercise_info?.title}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              } else
                return (
                  <div
                    className="inner-div workout-calendar-exercise-card my-1 "
                    style={{ position: "relative" }}
                  >
                    {" "}
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                      }}
                      className="py-1"
                    >
                      {exercise?.is_modified && (
                        <Tooltip
                          title={
                            exercise?.is_modified ? "Modified" : "Not Modified"
                          }
                        >
                          <span
                            style={{
                              display: "block",
                              position: "absolute",
                              top: "-5px",
                              justifyContent: "center",
                              zIndex: "1",
                              right: "0px",
                            }}
                          >
                            <Chip
                              label={"Modified"}
                              variant="contained"
                              color={"error"}
                              sx={{
                                fontSize: "6px",
                                height: "auto",
                                width: "auto",
                                borderRadius: "3px",
                                "& .MuiChip-label": {
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                },
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {exercise?.is_completed && (
                        <Tooltip
                          title={
                            exercise?.is_completed
                              ? "Completed"
                              : "Not Completed"
                          }
                        >
                          <Icon
                            icon="fluent-mdl2:completed-solid"
                            className="calendar-workout-card-complete-exercises-icon mb-1"
                          />
                        </Tooltip>
                      )}

                      {exercise?.exercise_info?.title}
                    </div>
                  </div>
                );
            }
          })}
          {extra && extra.length > 0 && (
            <div>
              <div className="text-center">
                <Typography
                  sx={{
                    color: get_root_value("--portal-theme-primary"),
                    fontWeight: "500",
                  }}
                >
                  <Icon icon="mdi:bicycle-electric" /> Extra Work
                </Typography>
                <div
                  style={{
                    whiteSpace: "break-spaces",
                    fontSize: 10,
                    opacity: 0.8,
                  }}
                >
                  These exercises aren’t part of the original workout
                </div>
              </div>

              <div>
                {extra.map((exercise, index) => {
                  if (
                    exercise?.sub_exercises &&
                    exercise?.sub_exercises.length > 0
                  ) {
                    return (
                      <div className="workout-calendar-Superset-card inner-div my-1">
                        {exercise?.sub_exercises?.map((exerData, exerIndex) => {
                          return (
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {exerData?.exercise_info?.title}
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else
                    return (
                      <div className="inner-div workout-calendar-exercise-card my-1">
                        {" "}
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {exercise?.exercise_info?.title}
                        </div>
                      </div>
                    );
                })}
              </div>
            </div>
          )}
          <div className="mt-2">
            {item?.comment && (
              <div
                style={{
                  whiteSpace: "break-spaces",
                  fontSize: 10,
                  opacity: 0.8,
                  fontWeight: "500",
                  color: "black",
                }}
              >
                <span
                  style={{
                    fontSize: 10,
                    opacity: 1,
                    fontWeight: "bold",
                    display: "block",
                    color: get_root_value("--portal-theme-primary"),
                  }}
                >
                  Comment
                </span>
                {item?.comment}
              </div>
            )}
          </div>
          <div className="mt-2">
            {item?.admin_comment && (
              <div
                style={{
                  whiteSpace: "break-spaces",
                  fontSize: 10,
                  opacity: 0.8,
                  fontWeight: "500",
                  color: "black",
                }}
              >
                <span
                  style={{
                    fontSize: 10,
                    opacity: 1,
                    fontWeight: "bold",
                    display: "block",
                    color: get_root_value("--portal-theme-primary"),
                  }}
                >
                  Reply
                </span>
                {item?.admin_comment}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOutList;
