import React, { useEffect, useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useSnackbar } from "notistack";
import { get_shoping_list_api } from "src/DAL/Profile/Profile";
import { _get_user_from_localStorage_new } from "src/DAL/localStorage/localStorage";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { getValidMealArray } from "src/utils/constants";
import { Link as RouterLink } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { logo_main } from "src/assets";

const ShoppingList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);

  let user_info = _get_user_from_localStorage_new();

  const getTotalNutrient = (meals, nutrient, type) => {
    if (!meals && !Array.isArray(meals)) return "0.00";

    return meals
      .reduce((total, meal) => {
        if (!Array.isArray(meal[type])) return total;
        return (
          total +
          meal[type].reduce((mealTotal, food) => {
            const value = food?.measurement_units?.[nutrient] || 0;
            const quantity = parseFloat(food?.quantity) || 0;
            return mealTotal + value * (quantity / 100);
          }, 0)
        );
      }, 0)
      .toFixed(2);
  };

  const getTotalCalories = (meals, type) => {
    const totalCarbs = parseFloat(getTotalNutrient(meals, "carb", type));
    const totalProtein = parseFloat(getTotalNutrient(meals, "protein", type));
    const totalFat = parseFloat(getTotalNutrient(meals, "fat", type));

    return (totalCarbs * 4 + totalProtein * 4 + totalFat * 9).toFixed(2);
  };

  const getQuantity = (qun, mealsData) => {
    let quantity = 0;
    if (user_info?.regular_day_calories) {
      quantity =
        (user_info?.regular_day_calories /
          getTotalCalories(mealsData, "meal_food_array")) *
        qun;
    } else {
      return qun;
    }
    return quantity.toFixed(2);
  };

  const getQuantityPercentage = (qun) => {
    let quantity = 0;
    if (user_info?.deficit_value && user_info?.deficit_percentage) {
      let deficitPercentage = user_info?.deficit_value / 100;
      quantity = qun * (1 - deficitPercentage);
      return Number(quantity).toFixed(2);
    } else {
      return qun;
    }
  };
  function consolidateMealFoods(mealData) {
    if (!mealData || !Array.isArray(mealData)) {
      return [];
    }

    let foodMap = {};

    mealData.forEach((meal) => {
      if (!meal.meal_food_array || !Array.isArray(meal.meal_food_array)) return;

      meal.meal_food_array.forEach((item) => {
        let name = item.name;
        let quantity = parseFloat(item.quantity);

        if (foodMap[name]) {
          foodMap[name] += quantity;
        } else {
          foodMap[name] = quantity;
        }
      });
    });

    return Object.keys(foodMap).map((name) => ({
      name,
      quantity: foodMap[name].toFixed(2),
    }));
  }

  const get_shoping_list = async () => {
    setIsLoading(true);
    const postData = {
      dates: dates.map((item) => item.format("YYYY-MM-DD")),
    };
    const result = await get_shoping_list_api(postData);
    if (result.code === 200) {
      let meals_array = [];

      if (result.combined_workouts && result.combined_workouts.length > 0) {
        meals_array = result.combined_workouts.map((item) => {
          if (item.workout_source == "program") {
            return {
              meals: item.meals.map((meal) => {
                return {
                  ...meal,
                  meal_food_array: meal?.meal_food_array
                    ?.map((unit) => {
                      if (unit.measurement_units) {
                        return {
                          name: unit?.selected_food?.name,
                          quantity: getQuantity(unit?.quantity, item.meals),
                          workout_source: unit?.workout_source,
                        };
                      }
                      return null;
                    })
                    .filter(Boolean),
                };
              }),
            };
          } else {
            return {
              meals: item.meals.map((meal) => {
                return {
                  ...meal,
                  meal_food_array: meal?.meal_food_array
                    ?.map((unit) => {
                      if (unit.measurement_units) {
                        return {
                          name: unit?.selected_food?.name,
                          quantity: unit?.quantity,
                          workout_source: unit?.workout_source,
                        };
                      }
                      return null;
                    })
                    .filter(Boolean),
                };
              }),
            };
          }
        });
      }

      let combined_workouts = meals_array.flatMap((item) => item.meals);

      let allMeals = getValidMealArray(combined_workouts);

      if (allMeals && allMeals.length > 0) {
        let data = allMeals.map((meal) => {
          return {
            meal_food_array: meal?.meal_food_array,
          };
        });

        let update_array = consolidateMealFoods(data);

        if (update_array && update_array.length > 0) {
          let deficit_array = update_array.map((item) => {
            return {
              ...item,
              quantity: getQuantityPercentage(item?.quantity),
            };
          });
          setListData(deficit_array);
        }
      }

      if (result?.combined_workouts.length > 0) {
        enqueueSnackbar(
          "The shopping list for the selected dates has been successfully created.",
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(
          "There are no food items to purchase for the selected dates.",
          { variant: "warning" }
        );
        setListData([]);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const downloadList = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Shopping List", 14, 15);

    doc.setFontSize(12);
    doc.text(
      `Name: ${user_info?.first_name + " " + user_info?.last_name}`,
      14,
      25
    );
    doc.text(`Email: ${user_info?.email}`, 14, 32);
    doc.addImage(logo_main, "PNG", 165, 10, 30, 25);

    const headers = [["Food Name", "Quantity"]];
    const data = listData.map((item) => [item.name, `${item.quantity} g`]);

    autoTable(doc, {
      head: headers,
      body: data,
      startY: 40,
      headStyles: {
        fillColor: [4, 171, 187],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    });

    doc.save("Food_List.pdf");
  };

  // useEffect(() => {
  //   const storedData = JSON.parse(localStorage.getItem("deficit_array")) || [];
  //   setListData(storedData);
  // }, []);

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <div className="me-2">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <h2>Shopping List</h2>
        </div>
        {!user_info?.regular_day_calories ? (
          <div className="col-12 mt-3">
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="start"
            >
              <Typography
                variant="subtitle2"
                sx={{
                  opacity: 0.6,
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                To use the shopping list feature, please{" "}
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  to={`/edit-profile`}
                >
                  Click here
                </Link>{" "}
                to complete your missing details (Gender, Weight, Height, Age,
                and Activity).
              </Typography>
            </Stack>
          </div>
        ) : (
          <>
            <div className="col-12 col-md-6 border_right">
              <div className="row my-3 align-items-center">
                <p
                  className={`col-12 mb-0 text-muted ${
                    dates.length > 0 ? "col-md-7" : "col-md-12"
                  }`}
                >
                  You can select one or multiple days for the shopping list by
                  clicking the dates on the calendar.
                </p>
                {dates.length > 0 && (
                  <div className="col-12 col-md-5 text-end mt-3 mt-md-0">
                    <Button
                      variant="contained"
                      disabled={isLoading}
                      onClick={get_shoping_list}
                    >
                      {isLoading ? "Loading..." : "Create Shopping List"}
                    </Button>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-center">
                <Calendar
                  value={dates}
                  className="custom-calendar"
                  onChange={setDates}
                  multiple
                  format="DD-MM-YYYY"
                  weekNumbers
                  plugins={[
                    <DatePanel
                      sort="date"
                      position="bottom"
                      className="custom-date-panel"
                    />,
                  ]}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 fix_height">
              <div className="row my-3 align-items-center">
                <h3
                  className={`col-12 my-3 color_primary ${
                    listData?.length > 0 ? "col-md-7" : "col-md-12"
                  }`}
                >
                  Food Items List
                </h3>
                {listData?.length > 0 && (
                  <div className="col-12 col-md-5 text-end mt-3 mt-md-0">
                    <Button variant="contained" onClick={downloadList}>
                      Download PDF
                    </Button>
                  </div>
                )}
              </div>

              {listData && listData.length > 0 ? (
                <>
                  <ul className="list-group mb-3">
                    {listData.map((item, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        {index + 1}: {item.name}
                        <span className="badge bg_primary rounded-pill">
                          {item.quantity}g
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <div className="container">
                  <RecordNotFound />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShoppingList;
