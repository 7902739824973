import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";

import { ReactVideoDurationPlayer, ReactVideoPlayer } from "src/components";
import { programmeDetail } from "src/DAL/Programmes/Programmes";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [programmeDetailInfo, setProgrammeDetailInfo] = React.useState();
  const [programEndDay, setProgrammeEndDay] = React.useState("");

  const handleDetail = async () => {
    setIsLoading(true);

    let result = await programmeDetail(
      state?._id?._id || state?.program_info?.program_id
    );
    if (result.code === 200) {
      console.log(result.program, "okokok", state);
      setProgrammeDetailInfo(result.program);
      const startDay = moment(state?.start_date);
      const formattedDate = startDay
        .clone() // Clone to avoid modifying original date
        .add(result?.program?.no_of_weeks, "weeks")
        .format("YYYY-MM-DD");
      setProgrammeEndDay(formattedDate);
      console.log(formattedDate, "dsjfhkjsdhkjfa");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    handleDetail();
  }, [state?._id]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <Container>
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-8">
          <h2>{programmeDetailInfo?.title}</h2>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {programmeDetailInfo?.video_url == "" ? (
            <img
              width="100%"
              src={s3baseUrl + programmeDetailInfo.program_images.thumbnail_1}
            />
          ) : (
            <ReactVideoPlayer url={programmeDetailInfo?.video_url} />
          )}
        </div>
        <div className="col-2 section-space">
          <div
            className="d-flex"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            <div className="me-2">Type:</div>
            <div style={{ opacity: "0.6" }}>
              {htmlDecode(programmeDetailInfo?.program_length_type)}
            </div>
          </div>
        </div>
        <div className="col-2 section-space">
          <div
            className="d-flex align-items-center"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            <div className="me-2">Color:</div>
            <div
              style={{
                width: "50px",
                height: "10px",
                borderRadius: "5px",
                backgroundColor: programmeDetailInfo?.color,
              }}
            ></div>
          </div>
        </div>
        {programmeDetailInfo?.program_length_type == "limited" && (
          <>
            <div className="col-2 section-space">
              <div
                className="d-flex align-items-center"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                <div className="me-2">Started On</div>

                <div style={{ opacity: "0.6" }}>
                  {state?.start_date
                    ? htmlDecode(moment(state?.start_date).format("DD-MM-YYYY"))
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="col-2 section-space">
              <div
                className="d-flex align-items-center"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                <div className="me-2">Expire On</div>

                <div style={{ opacity: "0.6" }}>
                  {programEndDay ? htmlDecode(programEndDay) : "N/A"}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: programmeDetailInfo?.detailed_description,
              }}
            ></div>
          </p>
        </div>
      </div>
    </Container>
  );
}

export default ProgrammesDetail;
