import React, { useContext, useEffect, useState } from "react";
import { socketUrl } from "src/config/config";
import {
  get_consultant_data_localStorage,
  get_navbar_list_localStorage,
  get_project_info_localStorage,
  _get_admin_time_zone_localStorage,
  _get_client_dashboard_setting_localStorage,
  _get_content_setting_localStorage,
  _get_home_event_localStorage,
  _get_navbar_list_localStorage,
  _get_testmonial_localStorage,
  _get_user_from_localStorage,
  _get_videos_list_localStorage,
  _get_user_from_localStorage_new,
} from "src/DAL/localStorage/localStorage";
import { ProjectInfoApi } from "src/DAL/Profile/Profile";
import io from "socket.io-client";

const CreateContentSetting = React.createContext();
const content_setting = _get_content_setting_localStorage();
const client_dashboard_setting = _get_client_dashboard_setting_localStorage();
const get_testmonial_localStorage = _get_testmonial_localStorage();
const get_home_event_localStorage = _get_home_event_localStorage();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_project_info = get_project_info_localStorage();
const _get_user_info = _get_user_from_localStorage();
const _get_user_info_new = _get_user_from_localStorage_new();
const _get_consultant_data = get_consultant_data_localStorage();
const get_navbar_list = _get_navbar_list_localStorage();
const get_videos_list = _get_videos_list_localStorage();
export const useContentSetting = () => useContext(CreateContentSetting);

let socket = {};
socket = io(socketUrl + `?user_id=${_get_user_info._id}&role=client`);

export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [contentSettingData, setContentSettingData] = useState(content_setting);
  const [projectInfo, setProjectInfo] = useState(get_project_info);
  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [userInfoNew, setUserInfoNew] = useState(_get_user_info_new);
  const [consultantInfo, setConsultantInfo] = useState(_get_consultant_data);
  const [navbarList, setNavbarList] = useState(get_navbar_list);
  const [videoList, setVideoList] = useState(get_videos_list);
  const [stripeKey, setStripeKey] = useState("");
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [dashboardEventData, setDashboardEventData] = useState(
    get_home_event_localStorage
  );
  const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
    get_testmonial_localStorage
  );
  const [dashboardSettingData, setDashboardSettingData] = useState(
    client_dashboard_setting
  );

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleContentSettingData = (val) => {
    setContentSettingData(val);
  };
  const handleDashboardSettingData = (val) => {
    setDashboardSettingData(val);
  };
  const handleDashboardTestimonialData = (val) => {
    setDashboardTestimonialData(val);
  };
  const handleDashboardEventData = (val) => {
    setDashboardEventData(val);
  };
  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketUrl + `?user_id=${user_id}&role=client`);
  };

  const STOP_SOCKET_FORCEFULLY = () => {
    socket = io(socketUrl + `?user_id=${userInfo._id}&role=client`);
    socket.disconnect();
  };

  const handle_embed_tinymce_key = (key) => {
    var head = document.head;
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://cdn.tiny.cloud/1/${key}/tinymce/5/tinymce.min.js`;
    head.appendChild(script);
  };

  const handleUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleConsultantInfo = (val) => {
    setConsultantInfo(val);
  };

  const handleNavbarList = (val) => {
    setNavbarList(val);
  };
  const handleVideoList = (val) => {
    setVideoList(val);
  };
  const handleStripeKey = (val) => {
    setStripeKey(val);
  };
  //Getting Project Info from API
  const getProjectInfo = async () => {
    const result = await ProjectInfoApi();
    if (result.code === 200) {
      // console.log(result, "sdfjlksd");
      localStorage.setItem(
        `project_info`,
        JSON.stringify(result?.member_setting)
      );
      setProjectInfo(result?.member_setting);
    }
  };

  const collection = {
    adminTimeZone,
    navbarList,
    videoList,
    consultantInfo,
    userInfo,
    stripeKey,
    projectInfo,
    dashboardEventData,
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    setUserInfo,
    handleAdminTimeZone,
    handleUserInfo,
    handleConsultantInfo,
    handleStripeKey,
    handleNavbarList,
    handleVideoList,
    handleUnreadMessageCount,
    unreadMessageCount,
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
  };

  useEffect(() => {
    getProjectInfo();
  }, []);

  useEffect(() => {
    handle_embed_tinymce_key(contentSettingData?.tinymce_key);
  }, [contentSettingData?.tinymce_key]);

  useEffect(() => {
    localStorage.setItem(`user_data`, JSON.stringify(userInfo));
  }, [userInfo]);

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
