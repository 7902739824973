import {
  CircularProgress,
  TextField,
  Tooltip,
  Box,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stack,
  Typography,
  Link,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import CongratulationAnimation from "./component/CongratulationAnimation";

import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import ConfirmationWithDescription from "src/components/ConfirmationWithDescription";
import MUIAutocomplete from "src/components/MUIAutocomplete";
import {
  _get_user_from_localStorage,
  _get_user_from_localStorage_new,
} from "src/DAL/localStorage/localStorage";
import { getValidMealArray, show_proper_words } from "src/utils/constants";
import { Link as RouterLink } from "react-router-dom";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const FoodMattersAndMindsetUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  mainindex,
  isWorkoutCompleted,
  setIsWorkoutCompleted,
  practiceType,
  memberRewardData,
  handleSubmit,
  meals_data,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [foodList, setFoodList] = useState([]);
  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [congratulation, setCongratulation] = useState(false);
  const [openExerciseAlert, setOpenExerciseAlert] = useState(false);
  const [exerIndex, setExerIndex] = useState("");
  const [isHovered, setIsHovered] = useState("");
  const [showToggle, setShowToggle] = useState(false);
  const contentRef = useRef(null);

  const convertToPerGram = (value = 0, quantity = 1) => {
    if (isNaN(value) || isNaN(quantity)) return 0;
    return parseFloat(((value / 100) * quantity).toFixed(2));
  };

  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const maxLength = 50;

  const getPlainText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const plainText = getPlainText(addArray[mainindex].description);

  // Function to update intervalTime state
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleChangeComplete = (e, index) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];
    if (!checked && isWorkoutCompleted) {
      setExerIndex(index);
      setOpenExerciseAlert(true);
      return;
    }
    list[index]["is_completed"] = checked;

    setModalChangeExe(true);
    setaddArray(list);
    if (checked) {
      handleSubmit(true);
      // setCongratulation(true);
    }
  };
  const handleAgreeInExerciseSet = () => {
    const list = [...addArray];
    list[exerIndex]["is_completed"] = false;
    setIsWorkoutCompleted(false);
    setModalChangeExe(true);
    setOpenExerciseAlert(false);
    setaddArray(list);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };
  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (congratulation) {
  //       setCongratulation(false);
  //     }
  //   }, 2500);

  //   return () => clearTimeout(timeout); // Clean up the timeout to avoid memory leaks
  // }, [congratulation]);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3;
      setShowToggle(contentHeight > maxHeight);
    }
  }, [addArray[mainindex].description]);

  let total_kacl = 0;

  let user_info = _get_user_from_localStorage_new();

  if (
    user_info?.regular_day_calories &&
    meals_data?.editData?.target_day == "regular"
  ) {
    total_kacl = user_info?.regular_day_calories;
  } else if (
    user_info?.high_day_calories &&
    meals_data?.editData?.target_day == "high"
  ) {
    total_kacl = user_info?.high_day_calories;
  } else if (
    user_info?.low_day_calories &&
    meals_data?.editData?.target_day == "low"
  ) {
    total_kacl = user_info?.low_day_calories;
  }

  const getTotalNutrient = (meals, nutrient, type) => {
    if (!meals && !Array.isArray(meals)) return "0.00";

    return meals
      .reduce((total, meal) => {
        if (!Array.isArray(meal[type])) return total;
        return (
          total +
          meal[type].reduce((mealTotal, food) => {
            const value = food?.measurement_units?.[nutrient] || 0;
            const quantity = parseFloat(food?.quantity) || 0;
            return mealTotal + value * (quantity / 100);
          }, 0)
        );
      }, 0)
      .toFixed(2);
  };

  const getTotalCalories = (meals, type) => {
    const totalCarbs = parseFloat(getTotalNutrient(meals, "carb", type));
    const totalProtein = parseFloat(getTotalNutrient(meals, "protein", type));
    const totalFat = parseFloat(getTotalNutrient(meals, "fat", type));

    return (totalCarbs * 4 + totalProtein * 4 + totalFat * 9).toFixed(2);
  };

  let mealsData = getValidMealArray(meals_data?.editData?.meals);

  const getQuantity = (qun, type) => {
    let quantity = 0;
    if (user_info?.regular_day_calories) {
      quantity =
        (user_info?.regular_day_calories / getTotalCalories(mealsData, type)) *
        qun;
    } else {
      return qun;
    }
    return quantity.toFixed(2);
  };

  if (meals_data?.editData?.created_from === "program_workout") {
    if (mealsData && mealsData.length > 0) {
      let data = mealsData.map((meal) => {
        return {
          ...meal,
          meal_food_array:
            meal?.meal_food_array &&
            meal?.meal_food_array.length > 0 &&
            meal?.meal_food_array?.map((unit) => {
              return {
                ...unit,
                quantity: getQuantity(unit.quantity, "meal_food_array"),
              };
            }),
          meal_food_array_alt:
            meal?.meal_food_array_alt &&
            meal?.meal_food_array_alt.length > 0 &&
            meal?.meal_food_array_alt?.map((unit) => {
              return {
                ...unit,
                quantity: getQuantity(unit.quantity, "meal_food_array_alt"),
              };
            }),
        };
      });
      mealsData = data;
    }
  }

  const getQuantityPercentage = (qun) => {
    let quantity = 0;
    if (user_info?.deficit_value && user_info?.deficit_percentage) {
      let deficitPercentage = user_info?.deficit_value / 100;
      quantity = qun * (1 - deficitPercentage);
      return Number(quantity).toFixed(2);
    } else {
      return qun;
    }
  };

  const applyDeficit = () => {
    if (user_info?.deficit_value && user_info?.deficit_percentage) {
      let new_calories =
        getTotalCalories(mealsData, "meal_food_array") *
        (1 - user_info?.deficit_value / 100);
      return new_calories.toFixed(2);
    }
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      <ConfirmationWithDescription
        open={openExerciseAlert}
        setOpen={setOpenExerciseAlert}
        title={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "Opps!" :
          "Are you sure you want to take this action?"
        }
        descriotion={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "You are not able to uncomplete because you have insufficient points/coins." :
          `If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`
        }
        handleAgree={handleAgreeInExerciseSet}
        isJustForOk={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? true :
          false
        }
      />
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      {/* {congratulation && <CongratulationAnimation />} */}
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{
                width: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <div
                // direction="row"

                className="d-flex my-1 workout-set-card w-100"
                onMouseEnter={() => handleMouseLeave()}
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                  onMouseEnter={() => handleMouseLeave()}
                >
                  <div className="row " onMouseEnter={() => handleMouseLeave()}>
                    <div
                      className={`col-sm-12 ${"col-md-7"} col-lg-6 d-flex align-items-center`}
                    >
                      {addArray[mainindex].is_modified && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-11px",
                            justifyContent: "center",
                            zIndex: "1",
                            left: "-38px",
                            transform: "rotate(-53deg)",
                          }}
                        >
                          <Chip
                            label={"Modified"}
                            variant="contained"
                            color={"error"}
                            sx={{
                              fontSize: "10px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        </span>
                      )}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Name"
                        value={
                          addArray[mainindex].title
                            ? addArray[mainindex].title
                            : ""
                        }
                        name="title"
                        multiline
                        rows={addArray[mainindex].title ? null : 1}
                        onChange={(e) => handleChangeDes(e, mainindex)}
                        InputLabelProps={{
                          className: "textfiels-input-label",
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        label="Name"
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "13px",
                            paddingLeft: "6px",
                          },

                          inputProps: {
                            readOnly: true,
                          },
                        }}
                        sx={{
                          borderRadius: "5px",

                          fontSize: "13px",
                          width: "100%",
                        }}
                      />
                    </div>
                    {practiceType == "food" &&
                      mealsData &&
                      mealsData?.length > 0 && (
                        <>
                          {!user_info?.regular_day_calories ? (
                            <div className="col-12 mt-3">
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="start"
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    opacity: 0.6,
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                >
                                  Please{" "}
                                  <Link
                                    component={RouterLink}
                                    variant="subtitle2"
                                    to={`/edit-profile`}
                                  >
                                    Click here
                                  </Link>{" "}
                                  to enter your details (Gender, Weight, Height,
                                  Age, and Activity) to calculate your Regular
                                  Day calorie needs for food portioning.
                                </Typography>
                              </Stack>
                            </div>
                          ) : (
                            <>
                              <div className="col-12 mt-3 d-flex justify-content-between">
                                <h3 className="primary_color">Your Meal</h3>
                                <p className="mb-1 d-flex align-items-center justify-content-end">
                                  <span className="fw-bold me-3">
                                    Total Calories:&nbsp;
                                  </span>
                                  {getTotalCalories(
                                    mealsData,
                                    "meal_food_array"
                                  )}{" "}
                                  kcal
                                </p>
                              </div>
                              {user_info?.deficit_value &&
                              user_info?.deficit_percentage ? (
                                <p className="mb-1 d-flex align-items-center justify-content-end">
                                  <span className="fw-bold me-3">
                                    Total calories after deficit:&nbsp;
                                  </span>
                                  {applyDeficit()} kcal
                                </p>
                              ) : (
                                ""
                              )}
                              {user_info?.deficit_value &&
                              user_info?.deficit_percentage ? (
                                <p className="mb-1 text-muted w-100">
                                  Your calorie deficit is active at{" "}
                                  {user_info?.deficit_value}%, and your food
                                  portions have been adjusted accordingly.
                                </p>
                              ) : (
                                ""
                              )}
                              {mealsData.map((meal, mealindex) => {
                                return (
                                  <div
                                    className="col-12 col-md-6 mb-3"
                                    key={mealindex}
                                  >
                                    <div className="card meals_card p-2">
                                      <h4 className="primary_color fw-bold mb-2 text-center">
                                        {meal.meal_name} ({" "}
                                        {meal.meal_type === "pre"
                                          ? "Pre Workout"
                                          : meal.meal_type === "post"
                                          ? "Post Workout"
                                          : show_proper_words(
                                              meal.meal_type
                                            )}{" "}
                                        )
                                      </h4>
                                      {meal?.meal_food_array &&
                                        meal?.meal_food_array?.length > 0 && (
                                          <div className="col-12">
                                            <table className="table table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th>Food</th>
                                                  <th>Type</th>
                                                  <th>Quantity</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {meal?.meal_food_array?.map(
                                                  (units, index) => (
                                                    <tr key={index}>
                                                      <td>
                                                        {
                                                          units?.selected_food
                                                            ?.name
                                                        }
                                                      </td>
                                                      <td>
                                                        {units?.selected_food
                                                          ?.measurement_units &&
                                                        units?.selected_food
                                                          ?.measurement_units
                                                          .length > 0
                                                          ? `${units?.measurement_units?.measurement_unit_name}`
                                                          : ""}
                                                      </td>
                                                      <td
                                                        className={`${
                                                          user_info?.deficit_percentage
                                                            ? "text_warning"
                                                            : ""
                                                        }`}
                                                      >
                                                        {getQuantityPercentage(
                                                          units?.quantity
                                                        )}
                                                        g
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                            {meal?.meal_food_array_alt.length >
                                              0 && (
                                              <>
                                                <p
                                                  style={{
                                                    color:
                                                      "var(--portal-main-color)",
                                                  }}
                                                  className="text-center"
                                                >
                                                  Alternative Meal
                                                </p>
                                                <table className="table table-sm table-bordered">
                                                  <thead>
                                                    <tr>
                                                      <th>Food</th>
                                                      <th>Type</th>
                                                      <th>Quantity</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {meal?.meal_food_array_alt.map(
                                                      (units, index) => (
                                                        <tr key={index}>
                                                          <td>
                                                            {
                                                              units
                                                                ?.selected_food
                                                                ?.name
                                                            }
                                                          </td>
                                                          <td>
                                                            {units
                                                              ?.selected_food
                                                              ?.measurement_units &&
                                                            units?.selected_food
                                                              ?.measurement_units
                                                              .length > 0
                                                              ? `${units?.measurement_units?.measurement_unit_name}`
                                                              : ""}
                                                          </td>
                                                          <td
                                                            className={`${
                                                              user_info?.deficit_percentage
                                                                ? "text_warning"
                                                                : ""
                                                            }`}
                                                          >
                                                            {getQuantityPercentage(
                                                              units?.quantity
                                                            )}
                                                            g
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                  </div>

                  {addArray[mainindex].description && (
                    <div className="col-12 mt-3 something_else_description">
                      <div className="custom_description_title">
                        Description
                      </div>
                      <div className="custom_description_text">
                        <div
                          style={{
                            whiteSpace: isExpanded ? "normal" : "pre-wrap",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: isExpanded
                              ? addArray[mainindex].description
                              : plainText.substring(0, maxLength),
                          }}
                        />
                        {plainText.length > maxLength && (
                          <span
                            className="toggle-text"
                            style={{
                              color: "var(--portal-theme-primary)",
                              cursor: "pointer",
                            }}
                            onClick={toggleExpand}
                          >
                            {isExpanded ? "See Less" : "See More"}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {/* <div className=" mt-4">
                    <div
                      className="exercise-label-box"
                      style={{ minHeight: "90px" }}
                    >
                      <span className="outer-label">Description</span>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: addArray[mainindex].description,
                          }}
                        ></div>
                      </p>
                    </div>
                  </div> */}

                  <div className="d-flex justify-content-end mt-3">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ p: "3px" }}
                            checked={
                              addArray[mainindex].is_completed
                                ? addArray[mainindex].is_completed
                                : false
                            }
                            onChange={(e) => handleChangeComplete(e, mainindex)}
                          />
                        }
                        label="Mark as Complete"
                      />
                    </FormGroup>
                  </div>
                </Box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default FoodMattersAndMindsetUI;
