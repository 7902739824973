import {
  CircularProgress,
  TextField,
  Tooltip,
  Box,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import CongratulationAnimation from "./component/CongratulationAnimation";

import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import ConfirmationWithDescription from "src/components/ConfirmationWithDescription";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const SomeThingElseExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  mainindex,
  isWorkoutCompleted,
  setIsWorkoutCompleted,
  memberRewardData,
  practiceType,
  handleSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [congratulation, setCongratulation] = useState(false);

  const [isHovered, setIsHovered] = useState("");
  const [openExerciseAlert, setOpenExerciseAlert] = useState(false);
  const [exerIndex, setExerIndex] = useState("");
  const classes = useStyles();
  const [showToggle, setShowToggle] = useState(false);
  const contentRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const maxLength = 50;

  const getPlainText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const plainText = getPlainText(addArray[mainindex].description);

  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleChangeComplete = (e, index) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];
    if (!checked && isWorkoutCompleted) {
      setExerIndex(index);
      setOpenExerciseAlert(true);
      return;
    }
    list[index]["is_completed"] = checked;

    setModalChangeExe(true);
    setaddArray(list);
    if (checked) {
      handleSubmit(true);
      // setCongratulation(true);
    }
  };
  const handleAgreeInExerciseSet = () => {
    const list = [...addArray];
    list[exerIndex]["is_completed"] = false;
    setIsWorkoutCompleted(false);
    setModalChangeExe(true);
    setOpenExerciseAlert(false);
    setaddArray(list);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };
  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (congratulation) {
  //       setCongratulation(false);
  //     }
  //   }, 2500);

  //   return () => clearTimeout(timeout); // Clean up the timeout to avoid memory leaks
  // }, [congratulation]);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3;
      setShowToggle(contentHeight > maxHeight);
    }
  }, [addArray[mainindex].description]);

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      <ConfirmationWithDescription
        open={openExerciseAlert}
        setOpen={setOpenExerciseAlert}
        title={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "Opps!" :
          "Are you sure you want to take this action?"
        }
        descriotion={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "You are not able to uncomplete because you have insufficient points/coins." :
          `If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`
        }
        isJustForOk={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? true :
          false
        }
        handleAgree={handleAgreeInExerciseSet}
      />
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      {/* {congratulation && <CongratulationAnimation />} */}
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{
                width: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <div
                // direction="row"

                className="d-flex my-1 workout-set-card w-100"
                onMouseEnter={() => handleMouseLeave()}
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                  onMouseEnter={() => handleMouseLeave()}
                >
                  <div className="row " onMouseEnter={() => handleMouseLeave()}>
                    <div
                      className={`col-sm-12 ${"col-md-7"} col-lg-6 d-flex align-items-center`}
                    >
                      {addArray[mainindex].is_modified && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-11px",
                            justifyContent: "center",
                            zIndex: "1",
                            left: "-38px",
                            transform: "rotate(-53deg)",
                          }}
                        >
                          <Chip
                            label={"Modified"}
                            variant="contained"
                            color={"error"}
                            sx={{
                              fontSize: "10px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        </span>
                      )}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Name"
                        value={
                          addArray[mainindex].title
                            ? addArray[mainindex].title
                            : ""
                        }
                        name="title"
                        onChange={(e) => handleChangeDes(e, mainindex)}
                        InputLabelProps={{
                          className: "textfiels-input-label",
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        multiline
                        rows={addArray[mainindex].title ? null : 1}
                        label="Name"
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "13px",
                            paddingLeft: "6px",
                          },

                          inputProps: {
                            readOnly: true,
                          },
                        }}
                        sx={{
                          borderRadius: "5px",

                          fontSize: "13px",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="mt-3">
                    <div
                      style={{ padding: "56.25% 0 0 0", position: "relative" }}
                    >
                      <iframe
                        src="https://player.vimeo.com/video/1061941208?h=df86b43159&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameBorder={0}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen=""
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </div> */}
                  {addArray[mainindex].description && (
                    <div className="col-12 mt-3 something_else_description">
                      <div className="custom_description_title">
                        Description
                      </div>
                      <div className="custom_description_text">
                        <div
                          style={{
                            whiteSpace: isExpanded ? "normal" : "pre-wrap",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: isExpanded
                              ? addArray[mainindex].description
                              : plainText.substring(0, maxLength),
                          }}
                        />
                        {plainText.length > maxLength && (
                          <span
                            className="toggle-text"
                            style={{
                              color: "var(--portal-theme-primary)",
                              cursor: "pointer",
                            }}
                            onClick={toggleExpand}
                          >
                            {isExpanded ? "See Less" : "See More"}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {/* <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    placeholder="Description"
                    label="Description"
                    type="number"
                    value={addArray[mainindex].description}
                    name="description"
                    multiline
                    rows={3}
                    onChange={(e) => handleChangeDes(e, mainindex)}
                    InputLabelProps={{
                      className: "textfiels-input-label",
                      shrink: true,
                      style: { fontSize: "14px" },
                    }}
                    InputProps={{
                      className: "textfiels-input-value",
                      style: { fontSize: "14px" },
                      readOnly: true,
                    }}
                    sx={{
                      borderRadius: "5px",
                      // "& legend": { display: "none" },
                      // "& fieldset": { top: 0 },

                      mt: 2,
                      width: "100%",
                    }}
                  /> */}
                  <div className="d-flex justify-content-end mt-3">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ p: "3px" }}
                            checked={addArray[mainindex].is_completed}
                            onChange={(e) => handleChangeComplete(e, mainindex)}
                          />
                        }
                        label="Mark as Complete"
                      />
                    </FormGroup>
                  </div>
                </Box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default SomeThingElseExercisesUI;
