import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import roundFilterList from "@iconify/icons-ic/round-filter-list";

// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
// import Page from "../components/Page";
// import Label from "./Label";
// import Scrollbar from "../components/Scrollbar";
import Label from "src/components/Label";
// import { UserListHead } from "src/components/_dashboard/user";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import SearchNotFound from "src/components/SearchNotFound";
import UserListHead from "../user/UserListHeadNew";
import { CoinInfoApi } from "src/DAL/Profile/Profile";
import { convertToTitleCase } from "src/utils/convertHtml";
import UserListToolbar from "../user/UserListToolbarNew";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import CoinsFilter from "./CoinsFilter";

//
// import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  //   { id: "number", label: "#", alignRight: false },
  { id: "points_type", label: "Points Type", alignRight: false },
  { id: "points_earned", label: "Points ", alignRight: false },
  { id: "earned", label: "Earned/Deducted", alignRight: false },

  { id: "date", label: "Date", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  //conole.log.log(query, "query");
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}
const USERLIST = [
  {
    id: 1,
    question: "Current Average Working Hours Per Week?",
    avatarUrl: "/static/mock-images/avatars/avatar_3.jpg",
    status: "active",
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function RecentPointsTable({ recentMember }) {
  const navigate = useNavigate();
  const params = useLocation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const EMPTY_FILTER = {
    status: "",
    type: "",
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);

    setFilterState(filterStateUpdated);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const getcoinListing = async (data) => {
    // setIsLoading(true);

    try {
      setIsLoading(true);
      const result = await CoinInfoApi(page, rowsPerPage, data?.type);
      if (result.code === 200) {
        console.log(result?.reward_points, "dslfaksd");
        setFilterStateUpdated(data);

        setUserList(result?.reward_points);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  const searchFunction = () => {
    setPage(0);

    getcoinListing(filterState);
    handleCloseFilterDrawer();
    setIsLoading(true);
  };
  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER };

    setPage(0);
    // setPageCount(1);
    setFilterName("");
    setFilterState(clear_data);

    getcoinListing(clear_data);
    handleCloseFilterDrawer();
    setIsLoading(true);
    localStorage.removeItem("WorkoutListSearch");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    //conole.log(event.target.value, "this is value");
    setFilterName(event.target.value);
  };
  const handleNavigate = () => {
    navigate(`/consultant/add-consultant`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  // console.log(member, "membermembermember");
  useEffect(() => {
    getcoinListing(EMPTY_FILTER);
  }, [page, rowsPerPage]);

  const isUserNotFound = filteredUsers?.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  //conole.log(questionData, "questionData");
  //conole.log(params, "ok");

  return (
    <>
      {/* <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      /> */}
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <CoinsFilter
            filterData={filterState}
            setFilterData={setFilterState}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />

      <div className="row my-3">
        <div className="col-12 col-md-8 d-flex">
          <div className="me-2">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <h2>Coins info</h2>
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-end">
          <button
            className="small-contained-button me-1 "
            onClick={handleOpenFilterDrawer}
            style={{ position: "relative" }}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
      </div>
      <Card style={{ overflowX: "auto" }}>
        {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

        <TableContainer sx={{ minWidth: 450, mt: 2 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={userList?.length}
              // numSelected={selected.length}
              onRequestSort={handleRequestSort}
              // onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => {
                  const {
                    id,
                    points_type,
                    points_earned,
                    createdAt,
                    history_type,
                  } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={i}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell>
                        <Typography>
                          {points_type === "food"
                            ? "Nutrition"
                            : points_type === "workout"
                            ? "Fitness "
                            : convertToTitleCase(points_type)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{points_earned}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={
                            history_type !== "earned" ? "warning" : "success"
                          }
                        >
                          {convertToTitleCase(history_type)}
                        </Label>
                      </TableCell>
                      {/* <TableCell align="left">
                          <Typography sx={{ ml: 1 }}>
                            {history_type
                              ? convertToTitleCase(history_type)
                              : "N/A"}
                          </Typography>
                        </TableCell> */}
                      <TableCell align="left">
                        <Typography sx={{ ml: 1 }}>
                          {createdAt
                            ? moment(createdAt).format(" DD-MM-YYYY")
                            : "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={userList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
