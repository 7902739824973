import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { save_target_weight_api } from "src/DAL/exercise/exercise";
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { memberDetail } from "src/DAL/Profile/Profile";
import { makeStyles } from "@mui/styles";
import { _get_user_from_localStorage_new } from "src/DAL/localStorage/localStorage";

const select_array = [
  {
    name: "weight_unit",
    label: "Weight Unit",
    menuOption: [
      { value: "kg", label: "Kilograms" },
      { value: "lbs", label: "Pounds" },
    ],
  },
  {
    name: "chest_unit",
    label: "Chest Unit",
    menuOption: [
      { value: "cm", label: "Centimeters" },
      { value: "inch", label: "Inches" },
    ],
  },
  {
    name: "waist_unit",
    label: "Waist Unit",
    menuOption: [
      { value: "cm", label: "Centimeters" },
      { value: "inch", label: "Inches" },
    ],
  },
  {
    name: "hip_unit",
    label: "Hip Unit",
    menuOption: [
      { value: "cm", label: "Centimeters" },
      { value: "inch", label: "Inches" },
    ],
  },
  {
    name: "arm_unit",
    label: "Arm Unit",
    menuOption: [
      { value: "cm", label: "Centimeters" },
      { value: "inch", label: "Inches" },
    ],
  },
  {
    name: "thigh_unit",
    label: "Thigh Unit",
    menuOption: [
      { value: "cm", label: "Centimeters" },
      { value: "inch", label: "Inches" },
    ],
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "15%",
    marginBottom: "15%",
  },
}));

const AddUnits = ({ setOpenSetting, weight_list }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [inputs, setInputs] = useState({
    target_weight: "",
    weight_unit: "kg",
    chest_unit: "cm",
    waist_unit: "cm",
    hip_unit: "cm",
    arm_unit: "cm",
    thigh_unit: "cm",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    let postData = {
      target_weight: inputs.target_weight,
      weight_unit: inputs.weight_unit,
      chest_unit: inputs.chest_unit,
      waist_unit: inputs.waist_unit,
      hip_unit: inputs.hip_unit,
      arm_unit: inputs.arm_unit,
      thigh_unit: inputs.thigh_unit,
    };

    const result = await save_target_weight_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      localStorage.setItem(`user_data`, JSON.stringify(result?.member));
      localStorage.setItem(`user_data_new`, JSON.stringify(result?.member));
      localStorage.setItem("unit_added", true);
      setIsFormLoading(false);
      weight_list();
      setOpenSetting(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsFormLoading(false);
    }
  };

  // const getMemberDetail = async () => {
  //   setIsLoading(true);
  //   const result = await memberDetail();
  //   if (result.code === 200) {

  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     setIsLoading(false);
  //   }
  // };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    let userInfo = _get_user_from_localStorage_new();
    setInputs((prevValue) => ({
      ...prevValue,
      target_weight: userInfo?.target_weight,
      weight_unit: userInfo?.weight_unit,
      chest_unit: userInfo?.chest_unit,
      waist_unit: userInfo?.waist_unit,
      hip_unit: userInfo?.hip_unit,
      arm_unit: userInfo?.arm_unit,
      thigh_unit: userInfo?.thigh_unit,
    }));
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="px-4 pb-3">
      <p className="text-muted">
        Choose the units for your weight and measurements. You can update them
        anytime in Weight Tracker under 'Units'.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              type="number"
              id="outlined-basic"
              label="Target Weight"
              variant="outlined"
              name="target_weight"
              value={inputs.target_weight}
              required={true}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Kg</InputAdornment>
                ),
              }}
            />
          </div>
          {select_array.map((item, index) => {
            return (
              <div className="col-12 col-md-6 mt-3" key={index}>
                <FormControl fullWidth required>
                  <InputLabel id={`select-label-${index}`}>
                    {item.label}
                  </InputLabel>
                  <Select
                    labelId={`select-label-${index}`}
                    id={`select-${index}`}
                    name={item.name}
                    value={inputs[item.name]}
                    onChange={handleChange}
                    label={item.label}
                  >
                    {item.menuOption.map((option, optionIndex) => (
                      <MenuItem key={optionIndex} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            );
          })}
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isFormLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUnits;
