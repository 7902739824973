import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableFooter,
  TablePagination,
  IconButton,
  Table,
  Collapse,
  TableBody,
  Typography,
  TableRow,
  TableHead,
  TableCell,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Tooltip,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import CustomPopoverNew from "src/components/CustomPopoverNew";
import {
  del_weight_list_api,
  get_weight_list_api,
} from "src/DAL/exercise/exercise";
import AddUnits from "./AddUnits";
import AddWeight from "./AddWeight";
import WeightWaistChart from "src/components/WeightWaistChart";
import moment from "moment";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import ForeCastChart from "./ForeCastChart";
import { EditProfileApiDashBoard } from "src/DAL/Profile/Profile";
import { _get_user_from_localStorage_new } from "src/DAL/localStorage/localStorage";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import WeightFilter from "./WeightFilter";
import FilteredChipNew from "src/components/FilteredChipNew";
import { bms_date_format } from "src/utils/constants";
import EditNoteIcon from "@mui/icons-material/EditNote";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  date_from: null,
  date_to: null,
};

export default function WeightTracker() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [timePeriod, setTimePeriod] = useState("7_day");
  const [isLoading, setIsLoading] = useState(false);
  const [openWeight, setOpenWeight] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [userPre, setUserPre] = useState({});
  const [chartList, setChartList] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [dataList, setDataList] = useState(null);
  const [showAction, setShowAction] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [deficitAllow, setDeficitAllow] = useState(true);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  let userInfo = _get_user_from_localStorage_new();

  const weight_list = async (value) => {
    let start_data = "";
    let end_date = "";

    if (value?.date_from || value?.date_to) {
      if (!value?.date_from || !value?.date_to) {
        return enqueueSnackbar("Please enter both start and end date", {
          variant: "error",
        });
      }
    }

    if (value?.date_from) {
      start_data = moment(value?.date_from).format("YYYY-MM-DD");
    }

    if (value?.date_to) {
      end_date = moment(value?.date_to).format("YYYY-MM-DD");
    }

    setIsLoading(true);
    handleCloseFilterDrawer();
    const result = await get_weight_list_api(
      page,
      rowsPerPage,
      timePeriod,
      start_data,
      end_date
    );
    if (result.code === 200) {
      let data = result.weights.map((item) => {
        let new_date = "N/A";
        let new_time = "N/A";

        if (item.date) {
          new_date = moment(item.date).format("DD-MM-YYYY");
        }
        if (item.time) {
          new_time = moment(item.time, "HH:mm").format("hh:mm A");
        }
        return {
          ...item,
          new_date,
          new_time,
        };
      });

      let chipData = { ...value };

      if (chipData.date_to && chipData.date_from) {
        let date = `Start Date : ${bms_date_format(
          chipData.date_from
        )} End Date :  ${bms_date_format(chipData.date_to)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.date_from;
        delete chipData.date_to;
      }
      setUpdateFilterData(chipData);
      setUserList(data);
      setChartList(result.graph_weights);
      setDataList(result);
      setUserPre(result.user_preferences);
      setTotalCount(result.totalEntries);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  // const handleDeleteChip = (data) => {
  //   setPage(0);
  //   setPageCount(1);
  //   if (!data.date) {
  //     delete data.date;
  //     data.date_from = null;
  //     data.date_to = null;
  //   }
  //   localStorage.setItem("UserFormSListSearch", JSON.stringify(data));
  //   weight_list(data);
  //   setUpdateFilterData(data);
  //   setFilterData(data);
  // };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    weight_list(filterData);
    localStorage.setItem("UserFormSListSearch", JSON.stringify(filterData));
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    setFilterData(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    weight_list(EMPTY_FILTER);
    localStorage.removeItem("UserFormSListSearch");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (value) => {
    setEditValues(value);
    setOpenWeight(true);
  };
  const handleAgreeDelete = (value) => {
    setCategoryId(value._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await del_weight_list_api(categoryId);
    if (result.code === 200) {
      weight_list();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  function Row({ row, openRow, setOpenRow, rowIndex }) {
    const isOpen = openRow === rowIndex;

    const is_show =
      row?.arm || row?.hip || row?.thigh || row?.waist || row?.chest;

    return (
      <>
        <TableRow className={isOpen ? "border_bottom_0" : ""}>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            <CustomPopoverSection menu={MENU_OPTIONS} data={row} />
          </TableCell>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            {rowIndex + 1}
          </TableCell>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            {is_show ? (
              <div
                className="d-flex align-items-center pointer"
                onClick={() => setOpenRow(isOpen ? null : rowIndex)}
              >
                {isOpen ? <span>Hide</span> : <span>Show</span>}
                <IconButton size="small">
                  {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            ) : (
              "N/A"
            )}
          </TableCell>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            {moment(row?.date).format("DD-MM-YYYY")}
          </TableCell>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            {moment(row?.time, "HH:mm").format("hh:mm A")}
          </TableCell>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            {row?.current_weight}
          </TableCell>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            {row?.body_fat}
          </TableCell>
          <TableCell className={isOpen ? "border_bottom_0" : ""}>
            {Number(row?.lean_mass).toFixed(2)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            <Collapse in={isOpen} timeout={300} unmountOnExit>
              <div className="card small_card">
                <div className="card-body">
                  <Typography variant="h6" gutterBottom>
                    Measurements
                  </Typography>
                  <Table size="small" className="small_table">
                    <TableBody>
                      <TableRow>
                        {row?.chest && (
                          <TableCell>
                            <strong>Chest:</strong> {row?.chest}{" "}
                            {userPre?.chest_unit}
                          </TableCell>
                        )}
                        {row?.waist && (
                          <TableCell>
                            <strong>Waist:</strong> {row?.waist}{" "}
                            {userPre?.waist_unit}
                          </TableCell>
                        )}
                        {row?.hip && (
                          <TableCell>
                            <strong>Hip:</strong> {row?.hip} {userPre?.hip_unit}
                          </TableCell>
                        )}
                        {row?.arm && (
                          <TableCell>
                            <strong>Arm:</strong> {row?.arm} {userPre?.arm_unit}
                          </TableCell>
                        )}
                        {row?.thigh && (
                          <TableCell>
                            <strong>Thigh:</strong> {row?.thigh}{" "}
                            {userPre?.thigh_unit}
                          </TableCell>
                        )}
                      </TableRow>
                      {row?.note && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Box
                              sx={{
                                wordBreak: "break-word",
                                whiteSpace: "pre-line",
                              }}
                            >
                              <strong>Note:</strong> {row?.note}
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    setIsFormLoading(true);
    const result = await EditProfileApiDashBoard(formData, "weight");
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result?.member));
      localStorage.setItem(`user_data_new`, JSON.stringify(result?.member));
      enqueueSnackbar("Pace calculator updated successfully", {
        variant: "success",
      });
      setShowAction(false);
      setIsFormLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsFormLoading(false);
    }
  };

  let check_unit = localStorage.getItem("unit_added");
  let current_weight = "";

  if (userPre?.weight_unit == "kg") {
    current_weight = dataList?.current_weight_in_kg;
  } else {
    current_weight = dataList?.current_weight_in_lbs;
  }

  useEffect(() => {
    if (!check_unit) {
      setOpenSetting(true);
    }
  }, [check_unit]);

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("UserFormSListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    weight_list(filter_data);
  }, [page, rowsPerPage, timePeriod]);

  useEffect(() => {
    if (
      current_weight &&
      dataList?.target_weight &&
      dataList?.predictedDate &&
      userInfo?.deficit_percentage
    ) {
      if (current_weight == dataList?.target_weight) {
        setShowAction(true);
      }
    }
  }, [dataList, current_weight, userInfo]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Weight?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mt-3">
          <div className="col-12 col-md-4 mb-4">
            <h2>Weight Tracker</h2>
          </div>
          <div className="col-12 col-md-8 d-flex justify-content-end align-items-center">
            <button
              onClick={() => {
                setOpenWeight(true);
                setEditValues(null);
              }}
              className="small-contained-button"
            >
              Check In <AddIcon />
            </button>
            <button
              onClick={() => {
                setOpenSetting(true);
              }}
              className="small-contained-button ms-2"
            >
              Units
            </button>
          </div>
          <div
            className={
              current_weight &&
              dataList?.target_weight &&
              dataList?.predictedDate
                ? "col-12 col-md-7 mt-3"
                : "col-md-12"
            }
          >
            <div className="card p-4 border-0 d-flex flex-column h-100 shoping_card">
              <div className="d-flex justify-content-between align-items-center">
                <div></div>
                <>
                  <Box sx={{ width: 150 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Filter By
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => setTimePeriod(e.target.value)}
                        value={timePeriod}
                        label="Filter By"
                      >
                        <MenuItem value="7_day">7 Days</MenuItem>
                        <MenuItem value="30_day">30 Days</MenuItem>
                        <MenuItem value="90_day">90 Days</MenuItem>
                        <MenuItem value="1_year">1 Year</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </>
              </div>
              <WeightWaistChart
                height={350}
                chartList={chartList}
                userPre={userPre}
              />
            </div>
          </div>
          {current_weight &&
          dataList?.target_weight &&
          dataList?.predictedDate ? (
            <div className="col-12 col-md-5 mt-3">
              <div className="card p-4 border-0 d-flex flex-column h-100 shoping_card">
                <h3>Target Weight Achievement Forecast</h3>
                <p className="text-black text-muted">
                  Based on your current progress, you are expected to reach your
                  target weight by{" "}
                  {moment(dataList?.predictedDate).format("DD-MMMM-YYYY")}.
                </p>
                {dataList?.weightLoss && (
                  <p className="text-black text-muted">
                    You have lost {dataList?.weightLoss}kg weight in the last 2
                    weeks!
                  </p>
                )}
                <div className="d-flex justify-content-center">
                  <p className="text-black fw-bold me-2">
                    Your Target Weight: {dataList?.target_weight}{" "}
                    {userPre?.weight_unit}
                  </p>
                  <Tooltip title="Update Target Weight">
                    <EditNoteIcon
                      className="pointer"
                      style={{ fill: "#04abbb" }}
                      onClick={() => {
                        setOpenSetting(true);
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="mt-3">
                  <ForeCastChart
                    data={[current_weight, dataList?.target_weight]}
                    userPre={userPre}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-12 mt-4 text-end">
            <button
              onClick={handleOpenFilterDrawer}
              className="small-contained-button"
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
          <FilteredChipNew
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleClearFilter}
            onClear={handleClearFilter}
          />
          <div className="col-12">
            <div className="card table_card mt-3">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>#</TableCell>
                    <TableCell>Measurements</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Weight ({userPre?.weight_unit})</TableCell>
                    <TableCell>Body Fat (%)</TableCell>
                    <TableCell>Lean Mass ({userPre?.weight_unit})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.length > 0 ? (
                    <>
                      {userList.map((row, index) => (
                        <Row
                          key={index}
                          row={row}
                          openRow={open}
                          setOpenRow={setOpen}
                          rowIndex={index}
                        />
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div className="flex justify-center items-center py-4">
                          <p className="mb-0 text-center">No Data Found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TableFooter className="mui-table-footer mt-2">
                <div className="mt-2"></div>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100, 200, 500]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </div>
          </div>
        </div>
        <CustomPopoverNew
          isOpenPop={openWeight}
          isClosePop={setOpenWeight}
          title={editValues ? "Check In" : "Check In"}
          componentToPassDown={
            <AddWeight
              openWeight={openWeight}
              setOpenWeight={setOpenWeight}
              weight_list={weight_list}
              editValues={editValues}
              userPre={userPre}
            />
          }
        />
        <CustomPopoverNew
          is_hide_icon={check_unit ? false : true}
          isClosePop={check_unit ? setOpenSetting : () => {}}
          isOpenPop={openSetting}
          title="Units"
          componentToPassDown={
            <AddUnits
              openSetting={openSetting}
              setOpenSetting={setOpenSetting}
              weight_list={weight_list}
            />
          }
        />
        {userInfo?.deficit_percentage && (
          <CustomPopoverNew
            is_hide_icon={true}
            width="400px"
            isClosePop={() => {}}
            isOpenPop={showAction}
            title="Target Weight Reached - Next Steps"
            componentToPassDown={
              <div className="p-3">
                <p>
                  Congratulations! You have reached your target weight. Please
                  disable the calorie deficit.
                </p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={deficitAllow}
                      onChange={(e) => {
                        setDeficitAllow(e.target.checked);
                      }}
                    />
                  }
                  label="Calorie Deficit"
                />
                <br />
                <div className="text-end">
                  <Button
                    disabled={deficitAllow}
                    onClick={(e) => {
                      handleUpdate(e);
                    }}
                    variant="contained"
                  >
                    {isFormLoading ? "Loading..." : "Submit"}
                  </Button>
                </div>
              </div>
            }
          />
        )}
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <WeightFilter
              filterData={filterData}
              handleSelectOther={handleSelectOther}
              searchFunction={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          }
        />
      </div>
    </>
  );
}
