import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  FormControlLabel,
  Switch,
  Alert,
} from "@mui/material";
import { useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import { useEffect } from "react";
import {
  EditProfileApiDashBoard,
  memberDetail,
  profile_program_api,
} from "src/DAL/Profile/Profile";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import SummaryCard from "src/components/_dashboard/app/SummaryCard";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import WeightWaistChart from "src/components/WeightWaistChart";
import MainPart from "./CommunityFeeds/MainPart";
import WorkOutList from "./WorkOutList";
import PaceCalculator from "./PaceCalculator";
import {
  CALCULATE_VDOT_VALUE,
  GET_VDOT,
  paceFromVdot,
} from "src/utils/constants";
import CustomPopoverNew from "src/components/CustomPopoverNew";
import AddWeight from "./WeightTracker/AddWeight";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const findPaceObjectByVdot = (vdotValue) => {
  return paceFromVdot.find((paceObject) => paceObject.vdot == vdotValue);
};

export default function DashboardApp() {
  const navigate = useNavigate();
  const { userInfo } = useContentSetting();
  const { contentSettingData, dashboardSettingData } = useContentSetting();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProgram, setIsLoadingProgram] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [memberData, setMemberData] = useState({});
  const [memberData1, setMemberData1] = useState({});
  const [checkedPace, setCheckedPace] = useState(true);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [dummyData, setDummyData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [openWeight, setOpenWeight] = useState(false);
  const [userPre, setUserPre] = useState({});
  const [selectProgram, setSelectProgram] = useState("all");
  const [chartList, setChartList] = useState([]);
  const [memberVdotData, setMemberVdotDataData] = useState({
    time_interval: 0,
    distance: "5km",
    easy_long: "e_km",
    thre_shold: "t_km",
    interval: "i_400_m",
  });
  let vdot = {};

  let vdot_parsed = {
    race_type: memberVdotData?.distance,
    time_taken: checkedPace ? memberVdotData?.time_interval : 0,
  };
  if (vdot_parsed.race_type && vdot_parsed.time_taken) {
    vdot = {
      race_type: vdot_parsed.race_type,
      time_taken: vdot_parsed.time_taken,
      distance_unit: "km",
    };
    let sorted_vdots = GET_VDOT;
    let race_type = "marathon";
    if (vdot_parsed.race_type == "marathon") {
      vdot.distance = 42.195;
      race_type = "marathon";
      sorted_vdots = GET_VDOT.sort((a, b) => a.marathon - b.marathon);
    } else if (vdot_parsed.race_type == "half_marathon") {
      vdot.distance = 21.098;
      race_type = "half_marathon";
      sorted_vdots = GET_VDOT.sort((a, b) => a.half_marathon - b.half_marathon);
    } else if (vdot_parsed.race_type == "5km") {
      vdot.distance = 5;
      race_type = "5km";
      sorted_vdots = GET_VDOT.sort((a, b) => a["5km"] - b["5km"]);
    } else if (vdot_parsed.race_type == "10km") {
      vdot.distance = 10;
      race_type = "10km";
      sorted_vdots = GET_VDOT.sort((a, b) => a["10km"] - b["10km"]);
    } else if (vdot_parsed.race_type == "15km") {
      vdot.distance = 15;
      race_type = "15km";
      sorted_vdots = GET_VDOT.sort((a, b) => a["15km"] - b["15km"]);
    }

    vdot.vdot_value = CALCULATE_VDOT_VALUE(
      race_type,
      vdot_parsed.time_taken,
      sorted_vdots
    );
  }

  const vdot33Object = findPaceObjectByVdot(
    vdot.vdot_value ? vdot.vdot_value : 0
  );

  const getMemberData = async () => {
    setIsLoadingProgram(true);
    let id = "";
    if (selectProgram !== "all") {
      id = selectProgram;
    }
    const result = await memberDetail(id);
    if (result.code === 200) {
      setMemberData1({ ...result, ...result?.member });
      if (isLoading) {
        setMemberData({ ...result, ...result?.member });
        setChartList(result?.graph_weights);
        setUserPre(result?.user_preferences);
        let isCheck = result?.member?.pace_calculator;
        setMemberVdotDataData((inputs) => ({
          ...inputs,
          ["distance"]: isCheck ? result?.member?.vdot?.race_type : "5km",
          ["time_interval"]: parseInt(
            result?.member?.vdot?.time_taken
              ? isCheck
                ? result?.member?.vdot?.time_taken
                : 0
              : 0
          ),
        }));
        setCheckedPace(isCheck);
      }
      setIsLoadingProgram(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingProgram(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let VDOT = {
      race_type: checkedPace ? memberVdotData?.distance : "5km",
      time_taken: checkedPace ? memberVdotData?.time_interval : 0,
    };

    const formData = new FormData();
    formData.append("pace_calculator", checkedPace);
    formData.append("vdot", JSON.stringify(VDOT));
    if (checkedPace && memberVdotData?.time_interval == 0) {
      return enqueueSnackbar("Please enter a value in the Duration field.", {
        variant: "error",
      });
    }
    setIsFormLoading(true);
    const result = await EditProfileApiDashBoard(formData, "dashboard");
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result?.member));
      localStorage.setItem(`user_data_new`, JSON.stringify(result?.member));
      enqueueSnackbar("Pace calculator updated successfully", {
        variant: "success",
      });
      setMemberVdotDataData((inputs) => ({
        ...inputs,
        ["distance"]: checkedPace ? result?.member?.vdot?.race_type : "5km",
        ["time_interval"]: parseInt(
          result?.member?.vdot?.time_taken
            ? checkedPace
              ? result?.member?.vdot?.time_taken
              : 0
            : 0
        ),
      }));
      setIsFormLoading(false);
      setIsShow(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsFormLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectProgram(event.target.value);
  };

  useEffect(() => {
    getMemberData();
  }, [selectProgram]);

  useEffect(() => {
    setMemberVdotDataData((prevState) => {
      return {
        ...prevState,
        ["pace"]: vdot33Object
          ? vdot33Object[prevState?.easy_long + "_low"]
          : null,
        ["paceUp"]: vdot33Object
          ? vdot33Object[prevState?.easy_long + "_up"]
          : null,
        ["pace1"]: vdot33Object ? vdot33Object[prevState?.thre_shold] : null,
        ["pace2"]: vdot33Object ? vdot33Object[prevState?.interval] : null,
      };
    });
  }, [
    memberVdotData?.easy_long,
    memberVdotData?.thre_shold,
    memberVdotData?.interval,
    vdot?.vdot_value,
  ]);

  useEffect(() => {
    setDummyData([
      {
        color: "#fff",

        title: "Total Coins",
        tooltip: "Total Coins",
        count: memberData?.total_points ? memberData?.total_points : 0,
        icon: "mdi:currency-usd-circle",
        link: true,
        to: `/dashboard-coins-info`,
      },
      {
        color: "#fff",
        title: "Total Daily Coins (AP)",
        tooltip: "Total Daily Coins (Accountability Partner)",
        count: memberData?.reward_points?.today_coins
          ? memberData?.reward_points?.today_coins
          : 0,
        icon: "mdi:cash-multiple",
        link: false,
      },
      {
        color: "#fff",
        title: "",
        count: "Shopping List",
        tooltip: "Shopping List",
        icon: "mdi:cart-outline",
        link: true,
        link_text: "Create Shopping List →",
        to: `/dashboard/shopping-list`,
      },
    ]);
  }, [memberData]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-12 col-md-4 dashboard_description">
          {contentSettingData && contentSettingData.dashboard_description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: contentSettingData.dashboard_description,
              }}
            ></div>
          ) : (
            <h2 className="dashboard-heading">
              {dashboardSettingData?.wellcome_text}
            </h2>
          )}
        </div>{" "}
        <div className="col-12 col-md-8 mt-3 mt-md-0">
          {memberData?.gender === "female" && memberData?.is_menstrual_cycle ? (
            <Alert
              sx={{
                textAlign: "left",
                width: "100%",
                backgroundColor: "#FF7F00",
                color: "#fff",
                "& .MuiAlert-icon": {
                  color: "#fff",
                },
              }}
              severity="warning"
            >
              You are currently on your menstrual cycle. Please avoid heavy
              workouts during this phase.
            </Alert>
          ) : (
            ""
          )}
        </div>
        {dummyData.map((item, i) => (
          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <SummaryCard item={item} />
          </div>
        ))}
        <div className="col-12 col-md-7 mt-3">
          <div className="card p-4 border-0 d-flex flex-column h-100 shoping_card">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h3>Weight Tracker</h3>
                <p className="text-muted mb-0">
                  Your Last 30-Day Progress Summary
                </p>
              </div>
              <>
                <div className="go_to_community text-end p-0">
                  <span
                    className="pointer"
                    onClick={() => {
                      navigate(`/weight-tracker`);
                    }}
                  >
                    Go To Weight Tracker →
                  </span>
                </div>
              </>
            </div>
            <WeightWaistChart chartList={chartList} userPre={userPre} />
          </div>
        </div>
        <div className="col-12 col-md-5 d-flex flex-column">
          <div className="card border-0 flex-fill mt-3 shoping_card">
            <div className="go_to_community text-end">
              <span
                className="pointer"
                onClick={() => {
                  navigate(`/community-area`);
                }}
              >
                Go To Community Feed →
              </span>
            </div>
            <MainPart
              showInfoBox={showInfoBox}
              setShowInfoBox={setShowInfoBox}
              userInfo={userInfo}
              event_id=""
              dashboard={true}
            />
          </div>
        </div>
        <div className="col-12 col-md-7 mt-3">
          <div className="card p-4 border-0 shoping_card workout-container-fix">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>Daily Action Plan (DAP)</h3>
              <Box sx={{ width: 200 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Filter By
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectProgram}
                    onChange={handleChange}
                    label="Filter By"
                  >
                    <MenuItem value="all">ALL</MenuItem>
                    {memberData?.programme_array &&
                      memberData?.programme_array.length > 0 &&
                      memberData?.programme_array.map((item) => (
                        <MenuItem key={item.program_id} value={item.program_id}>
                          {item.programme_title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            {memberData1?.today_workout &&
            memberData1?.today_workout?.length > 0 ? (
              <>
                {isLoadingProgram ? (
                  <div className="text-center mt-5">
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <div className="workout-container">
                    {memberData1?.today_workout.map((item, index) => {
                      let extra = item?.exercises
                        .map((item) => {
                          if (item.is_extra) {
                            return item;
                          }
                          return [];
                        })
                        .flat();
                      return <WorkOutList item={item} extra={extra} />;
                    })}
                  </div>
                )}
              </>
            ) : (
              <RecordNotFound
                title={
                  "No tasks for today! Have a great day—stay healthy and happy!"
                }
              />
            )}
          </div>
        </div>
        <div className="col-12 col-md-5 d-flex flex-column">
          <div className="card p-4 border-0 mt-3 shoping_card flex-fill">
            <div className="d-flex align-items-center justify-content-between">
              <h3>Pace Calculator</h3>
              <>
                <FormControlLabel
                  className="me-0"
                  control={
                    <Switch
                      checked={checkedPace}
                      onChange={(e) => {
                        setCheckedPace(e.target.checked);
                        setIsShow(true);
                      }}
                    />
                  }
                />
              </>
            </div>
            <p className="text-muted mb-0">
              If running is part of your programme, get your current paces by
              entering a recent time trial result. These will show automatically
              inside your programme.
            </p>

            <PaceCalculator
              checkedPace={checkedPace}
              memberVdotData={memberVdotData}
              setMemberVdotDataData={setMemberVdotDataData}
              handleUpdate={handleUpdate}
              isFormLoading={isFormLoading}
              setIsShow={setIsShow}
              isShow={isShow}
            />
          </div>
        </div>
      </div>
      <CustomPopoverNew
        isOpenPop={openWeight}
        isClosePop={setOpenWeight}
        title={"Check In"}
        componentToPassDown={
          <AddWeight
            openWeight={openWeight}
            setOpenWeight={setOpenWeight}
            weight_list={getMemberData}
            editValues={null}
            userPre={userPre}
          />
        }
      />
    </div>
  );
}
