import moment from "moment/moment";
import {
  _get_admin_time_zone_localStorage,
  _get_user_from_localStorage,
} from "src/DAL/localStorage/localStorage";

export const timeAgo = (dateString) => {
  const ticketDate = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - ticketDate) / 1000);

  if (diffInSeconds < 60) return `few seconds ago`;
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  return `${Math.floor(diffInSeconds / 86400)} days ago`;
};

export const bms_date_format = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const getValidMealArray = (editData) => {
  if (
    editData &&
    Array.isArray(editData) &&
    editData.length > 0 &&
    editData[0] !== "[]" &&
    !!editData[0]
  ) {
    if (Array.isArray(editData) && editData.length > 0) {
      if (typeof editData[0] === "string") {
        return JSON.parse(editData);
      } else {
        return editData;
      }
    }
  } else {
    return [];
  }
};

export const is_small_screen = () => {
  if (window.innerWidth < 500) {
    return true;
  } else {
    return false;
  }
};

export function string_avatar(name) {
  if (name) {
    const splitted = name.split(" ");
    if (splitted.length > 1) {
      return `${splitted[0][0]}${splitted[1][0]}`.toUpperCase();
    } else if (splitted.length === 1) {
      return `${splitted[0][0]}`.toUpperCase();
    }
  }
}

export const get_local_preview = (file) => {
  if (file) {
    return URL.createObjectURL(file);
  } else {
    return "";
  }
};

export const show_proper_words = (text) => {
  let replace_string = "";
  if (text) {
    // Replace hyphens and underscores with spaces
    replace_string = text.replace(/[-_]/g, " ");
    // Capitalize the first letter of every word
    replace_string = replace_string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return replace_string;
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};

export const decode_markdown = (text) => {
  var outputText = text.replace(/\[(.*?)\]\s?\(.*?\)/g, "$1");
  var replaced = outputText.replace(/\*/g, "");
  return replaced;
};

export function sort_array_based_on_createdAt(conversations) {
  return conversations.sort((a, b) => {
    const createdAtA = new Date(a.lastMessageDateTime || a.createdAt);
    const createdAtB = new Date(b.lastMessageDateTime || b.createdAt);
    return createdAtB - createdAtA;
  });
}

export const getTimeBasedOnDate = (date, format) => {
  if (!date || !format || date === "1970-01-01T00:00:00.000Z") {
    return "";
  }

  const moment = require("moment-timezone");
  const dateTime = moment(date);
  return dateTime.format(format);
};

export const is_small_medium_screen = () => {
  if (window.innerWidth < 767) {
    return true;
  } else {
    return false;
  }
};
export const getValueText = (value) => {
  switch (value) {
    case "1rm":
      return "%1RM";
    case "body_weight":
      return "%BW";
    case "kg":
      return "kg";
    case "lb":
      return "pound";
    case "poods":
      return "poods";
    case "m":
      return "meter";
    case "km":
      return "km";
    case "mi":
      return "mile";
    case "calories":
      return "calories";
    case "el":
      return "Easy/Long";
    case "c":
      return "Compromised";
    case "t":
      return "Threshold";
    case "i":
      return "Interval";
    case "r":
      return "Repetition";
    case "cm":
      return "cm";
    case "ft":
      return "feet";
    case "inch":
      return "inch";
    default:
      return "";
  }
};
export const getTitleByKey = (key) => {
  switch (key) {
    case "e_km":
      return "1 KM Pace";
    case "e_mi":
      return "1 MILE Pace";
    case "i_400_m":
      return "400 Meters Pace";
    case "i_km":
      return "1 KM Pace";
    case "i_1200_m":
      return "1200 Meters Pace";
    case "i_mi":
      return "1 MILE Pace";
    case "t_400_m":
      return "400 Meters Pace";
    case "t_km":
      return "1 KM Pace";
    case "t_mi":
      return "1 MILE Pace";
    case "c_km":
      return "1 KM Pace";
    case "c_mi":
      return "1 MILE Pace";
    case "r_200_m":
      return "200 Meters Pace";
    case "r_300_m":
      return "300 Meters Pace";
    case "r_400_m":
      return "400 Meters Pace";
    case "r_600_m":
      return "600 Meters Pace";
    case "r_800_m":
      return "800 Meters Pace";
    default:
      return "";
  }
};
export const handleScrollCalled = (data) => {
  console.log("scrollkdsfkjhsdkjfal", data);
  const todayCell = document.querySelector(
    `.calendar-workout-card[id="${data}"]`
  ); // Find the DOM element corresponding to today's date
  if (todayCell) {
    todayCell.scrollIntoView({ behavior: "instant", block: "center" });
  }
};
export const get_date_with_user_time_zone = (
  date,
  format,
  user_time_zone,
  admin_time_zone
) => {
  var moment = require("moment-timezone");
  var admin_date_time = moment.tz(date, admin_time_zone);
  var user_date_time = admin_date_time.clone().tz(user_time_zone);
  return user_date_time.format(format);
};
export const TIME_ZONE_CONVERSION = (
  date,
  format,
  from_time_zone,
  to_time_zone
) => {
  console.log(
    date,
    format,
    from_time_zone,
    to_time_zone,
    "to_time_zoneto_time_zone"
  );
  var moment = require("moment-timezone");
  let formated_date = moment(date, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm"
  );
  let momentObj = moment.tz(
    formated_date,
    "YYYY-MM-DD HH:mm:ss",
    from_time_zone
  );

  let final_date = moment(momentObj).tz(to_time_zone).format(format);
  return final_date;
};
export const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertCurrencyToSign = (currency) => {
  if (currency === "gbp" || currency === "GBP") {
    return "£";
  } else if (currency === "eur" || currency === "EUR") {
    return "€";
  } else if (currency === "usd" || currency === "USD") {
    return "$";
  } else {
    return "";
  }
};

export const clickAbleUrl = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} target="_blank">
          {part}
        </a>
      );
    }
    return part;
  });
};

export const GET_VDOT = [
  {
    vdot: 30,
    "5km": 1840,
    "10km": 3826,
    "15km": 5894,
    half_marathon: 8464,
    marathon: 17357,
  },
  {
    vdot: 31,
    "5km": 1791,
    "10km": 3723,
    "15km": 5736,
    half_marathon: 8241,
    marathon: 16917,
  },
  {
    vdot: 32,
    "5km": 1745,
    "10km": 3626,
    "15km": 5587,
    half_marathon: 8029,
    marathon: 16499,
  },
  {
    vdot: 33,
    "5km": 1701,
    "10km": 3534,
    "15km": 5445,
    half_marathon: 7827,
    marathon: 16102,
  },
  {
    vdot: 34,
    "5km": 1659,
    "10km": 3446,
    "15km": 5310,
    half_marathon: 7636,
    marathon: 15723,
  },
  {
    vdot: 35,
    "5km": 1620,
    "10km": 3363,
    "15km": 5182,
    half_marathon: 7453,
    marathon: 15363,
  },
  {
    vdot: 36,
    "5km": 1582,
    "10km": 3284,
    "15km": 5060,
    half_marathon: 7279,
    marathon: 15019,
  },
  {
    vdot: 37,
    "5km": 1546,
    "10km": 3209,
    "15km": 4944,
    half_marathon: 7114,
    marathon: 14690,
  },
  {
    vdot: 38,
    "5km": 1512,
    "10km": 3137,
    "15km": 4833,
    half_marathon: 6955,
    marathon: 14375,
  },
  {
    vdot: 39,
    "5km": 1479,
    "10km": 3069,
    "15km": 4727,
    half_marathon: 6814,
    marathon: 14074,
  },
  {
    vdot: 40,
    "5km": 1448,
    "10km": 3003,
    "15km": 4626,
    half_marathon: 6659,
    marathon: 13785,
  },
  {
    vdot: 41,
    "5km": 1418,
    "10km": 2941,
    "15km": 4529,
    half_marathon: 6520,
    marathon: 13509,
  },
  {
    vdot: 42,
    "5km": 1389,
    "10km": 2881,
    "15km": 4436,
    half_marathon: 6387,
    marathon: 13243,
  },
  {
    vdot: 43,
    "5km": 1361,
    "10km": 2824,
    "15km": 4347,
    half_marathon: 6260,
    marathon: 12988,
  },
  {
    vdot: 44,
    "5km": 1335,
    "10km": 2769,
    "15km": 4262,
    half_marathon: 6137,
    marathon: 12743,
  },
  {
    vdot: 45,
    "5km": 1310,
    "10km": 2716,
    "15km": 4180,
    half_marathon: 6020,
    marathon: 12506,
  },
  {
    vdot: 46,
    "5km": 1285,
    "10km": 2665,
    "15km": 4102,
    half_marathon: 5907,
    marathon: 12279,
  },
  {
    vdot: 47,
    "5km": 1262,
    "10km": 2616,
    "15km": 4026,
    half_marathon: 5798,
    marathon: 12060,
  },
  {
    vdot: 48,
    "5km": 1239,
    "10km": 2570,
    "15km": 3953,
    half_marathon: 5693,
    marathon: 11849,
  },
  {
    vdot: 49,
    "5km": 1218,
    "10km": 2524,
    "15km": 3884,
    half_marathon: 5592,
    marathon: 11646,
  },
  {
    vdot: 50,
    "5km": 1197,
    "10km": 2481,
    "15km": 3816,
    half_marathon: 5495,
    marathon: 11449,
  },
  {
    vdot: 51,
    "5km": 1176,
    "10km": 2439,
    "15km": 3751,
    half_marathon: 5402,
    marathon: 11259,
  },
  {
    vdot: 52,
    "5km": 1157,
    "10km": 2399,
    "15km": 3689,
    half_marathon: 5312,
    marathon: 11076,
  },
  {
    vdot: 53,
    "5km": 1138,
    "10km": 2360,
    "15km": 3628,
    half_marathon: 5224,
    marathon: 10899,
  },
  {
    vdot: 54,
    "5km": 1120,
    "10km": 2322,
    "15km": 3570,
    half_marathon: 5140,
    marathon: 10727,
  },
  {
    vdot: 55,
    "5km": 1102,
    "10km": 2286,
    "15km": 3513,
    half_marathon: 5058,
    marathon: 10561,
  },
  {
    vdot: 56,
    "5km": 1085,
    "10km": 2251,
    "15km": 3459,
    half_marathon: 4980,
    marathon: 10400,
  },
  {
    vdot: 57,
    "5km": 1069,
    "10km": 2217,
    "15km": 3406,
    half_marathon: 4903,
    marathon: 10245,
  },
  {
    vdot: 58,
    "5km": 1053,
    "10km": 2184,
    "15km": 3355,
    half_marathon: 4830,
    marathon: 10094,
  },
  {
    vdot: 59,
    "5km": 1037,
    "10km": 2152,
    "15km": 3306,
    half_marathon: 4758,
    marathon: 9947,
  },
  {
    vdot: 60,
    "5km": 1023,
    "10km": 2122,
    "15km": 3258,
    half_marathon: 4689,
    marathon: 9805,
  },
  {
    vdot: 61,
    "5km": 1008,
    "10km": 2092,
    "15km": 3212,
    half_marathon: 4622,
    marathon: 9668,
  },
  {
    vdot: 62,
    "5km": 994,
    "10km": 2063,
    "15km": 3167,
    half_marathon: 4557,
    marathon: 9534,
  },
  {
    vdot: 63,
    "5km": 980,
    "10km": 2035,
    "15km": 3123,
    half_marathon: 4494,
    marathon: 9404,
  },
  {
    vdot: 64,
    "5km": 967,
    "10km": 2008,
    "15km": 3081,
    half_marathon: 4433,
    marathon: 9278,
  },
  {
    vdot: 65,
    "5km": 954,
    "10km": 1981,
    "15km": 3040,
    half_marathon: 4373,
    marathon: 9155,
  },
  {
    vdot: 66,
    "5km": 942,
    "10km": 1955,
    "15km": 3000,
    half_marathon: 4316,
    marathon: 9036,
  },
  {
    vdot: 67,
    "5km": 929,
    "10km": 1931,
    "15km": 2962,
    half_marathon: 4260,
    marathon: 8920,
  },
  {
    vdot: 68,
    "5km": 918,
    "10km": 1906,
    "15km": 2924,
    half_marathon: 4205,
    marathon: 8807,
  },
  {
    vdot: 69,
    "5km": 906,
    "10km": 1883,
    "15km": 2888,
    half_marathon: 4152,
    marathon: 8697,
  },
  {
    vdot: 70,
    "5km": 895,
    "10km": 1860,
    "15km": 2852,
    half_marathon: 4101,
    marathon: 8590,
  },
  {
    vdot: 71,
    "5km": 884,
    "10km": 1838,
    "15km": 2818,
    half_marathon: 4041,
    marathon: 8486,
  },
  {
    vdot: 72,
    "5km": 873,
    "10km": 1816,
    "15km": 2784,
    half_marathon: 4002,
    marathon: 8384,
  },
  {
    vdot: 73,
    "5km": 863,
    "10km": 1795,
    "15km": 2751,
    half_marathon: 3954,
    marathon: 8285,
  },
  {
    vdot: 74,
    "5km": 853,
    "10km": 1774,
    "15km": 2719,
    half_marathon: 3908,
    marathon: 8189,
  },
  {
    vdot: 75,
    "5km": 843,
    "10km": 1754,
    "15km": 2688,
    half_marathon: 3863,
    marathon: 8155,
  },
  {
    vdot: 76,
    "5km": 834,
    "10km": 1735,
    "15km": 2658,
    half_marathon: 3819,
    marathon: 8003,
  },
  {
    vdot: 77,
    "5km": 824,
    "10km": 1716,
    "15km": 2629,
    half_marathon: 3776,
    marathon: 7914,
  },
  {
    vdot: 78,
    "5km": 815,
    "10km": 1697,
    "15km": 2600,
    half_marathon: 3735,
    marathon: 7827,
  },
  {
    vdot: 79,
    "5km": 806,
    "10km": 1679,
    "15km": 2572,
    half_marathon: 3694,
    marathon: 7742,
  },
  {
    vdot: 80,
    "5km": 797.8,
    "10km": 1661,
    "15km": 2545,
    half_marathon: 3654,
    marathon: 7658,
  },
  {
    vdot: 81,
    "5km": 789.3,
    "10km": 1644,
    "15km": 2518,
    half_marathon: 3615,
    marathon: 7577,
  },
  {
    vdot: 82,
    "5km": 781.1,
    "10km": 1627,
    "15km": 2492,
    half_marathon: 3578,
    marathon: 7497,
  },
  {
    vdot: 83,
    "5km": 773,
    "10km": 1611,
    "15km": 2466,
    half_marathon: 3541,
    marathon: 7420,
  },
  {
    vdot: 84,
    "5km": 765.2,
    "10km": 1594,
    "15km": 2442,
    half_marathon: 3505,
    marathon: 7344,
  },
  {
    vdot: 85,
    "5km": 757.4,
    "10km": 1579,
    "15km": 2417,
    half_marathon: 3470,
    marathon: 7270,
  },
];

export const CALCULATE_VDOT_VALUE = (race_type, time, vdots) => {
  let closest_vdot = null;
  let min_difference = Infinity;

  for (const item of vdots) {
    const difference = Math.abs(item[race_type] - time);
    if (difference < min_difference) {
      min_difference = difference;
      closest_vdot = item.vdot;
    }
  }
  return closest_vdot;
};

export const paceFromVdot = [
  {
    vdot: 30,
    e_km_low: 447,
    e_km_up: 494,
    e_mi_low: 720,
    e_mi_up: 796,
    c_km: 423,
    c_mi: 681,
    t_400_m: 153,
    t_km: 384,
    t_mi: 618,
    i_400_m: 142,
    i_km: null,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 67,
    r_300_m: 101,
    r_400_m: null,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 31,
    e_km_low: 436,
    e_km_up: 482,
    e_mi_low: 701,
    e_mi_up: 777,
    c_km: 412,
    c_mi: 662,
    t_400_m: 150,
    t_km: 374,
    t_mi: 602,
    i_400_m: 138,
    i_km: null,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 65,
    r_300_m: 98,
    r_400_m: null,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 32,
    e_km_low: 425,
    e_km_up: 472,
    e_mi_low: 684,
    e_mi_up: 759,
    c_km: 400,
    c_mi: 644,
    t_400_m: 146,
    t_km: 365,
    t_mi: 587,
    i_400_m: 134,
    i_km: null,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 63,
    r_300_m: 95,
    r_400_m: null,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 33,
    e_km_low: 415,
    e_km_up: 461,
    e_mi_low: 667,
    e_mi_up: 12,
    c_km: 390,
    c_mi: 627,
    t_400_m: 143,
    t_km: 356,
    t_mi: 573,
    i_400_m: 131,
    i_km: null,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 61,
    r_300_m: 92,
    r_400_m: null,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 34,
    e_km_low: 405,
    e_km_up: 451,
    e_mi_low: 652,
    e_mi_up: 12,
    c_km: 380,
    c_mi: 611,
    t_400_m: 139,
    t_km: 348,
    t_mi: 560,
    i_400_m: 128,
    i_km: null,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 60,
    r_300_m: 90,
    r_400_m: 120,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 35,
    e_km_low: 396,
    e_km_up: 441,
    e_mi_low: 637,
    e_mi_up: 709,
    c_km: 370,
    c_mi: 596,
    t_400_m: 136,
    t_km: 340,
    t_mi: 547,
    i_400_m: 125,
    i_km: null,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 58,
    r_300_m: 87,
    r_400_m: 117,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 36,
    e_km_low: 387,
    e_km_up: 431,
    e_mi_low: 623,
    e_mi_up: 694,
    c_km: 361,
    c_mi: 581,
    t_400_m: 133,
    t_km: 353,
    t_mi: 535,
    i_400_m: 122,
    i_km: null,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 57,
    r_300_m: 85,
    r_400_m: 114,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 37,
    e_km_low: 379,
    e_km_up: 422,
    e_mi_low: 609,
    e_mi_up: 680,
    c_km: 353,
    c_mi: 568,
    t_400_m: 130,
    t_km: 326,
    t_mi: 524,
    i_400_m: 119,
    i_km: 300,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 55,
    r_300_m: 83,
    r_400_m: 111,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 38,
    e_km_low: 371,
    e_km_up: 414,
    e_mi_low: 596,
    e_mi_up: 666,
    c_km: 345,
    c_mi: 555,
    t_400_m: 127,
    t_km: 319,
    t_mi: 513,
    i_400_m: 116,
    i_km: 294,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 54,
    r_300_m: 81,
    r_400_m: 108,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 39,
    e_km_low: 363,
    e_km_up: 406,
    e_mi_low: 584,
    e_mi_up: 653,
    c_km: 337,
    c_mi: 542,
    t_400_m: 125,
    t_km: 312,
    t_mi: 502,
    i_400_m: 114,
    i_km: 288,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 53,
    r_300_m: 80,
    r_400_m: 106,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 40,
    e_km_low: 356,
    e_km_up: 398,
    e_mi_low: 572,
    e_mi_up: 641,
    c_km: 329,
    c_mi: 530,
    t_400_m: 122,
    t_km: 306,
    t_mi: 492,
    i_400_m: 112,
    i_km: 282,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 52,
    r_300_m: 78,
    r_400_m: 104,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 41,
    e_km_low: 349,
    e_km_up: 391,
    e_mi_low: 561,
    e_mi_up: 628,
    c_km: 322,
    c_mi: 519,
    t_400_m: 120,
    t_km: 300,
    t_mi: 482,
    i_400_m: 110,
    i_km: 276,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 51,
    r_300_m: 77,
    r_400_m: 102,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 42,
    e_km_low: 342,
    e_km_up: 383,
    e_mi_low: 550,
    e_mi_up: 617,
    c_km: 316,
    c_mi: 508,
    t_400_m: 117,
    t_km: 294,
    t_mi: 472,
    i_400_m: 108,
    i_km: 271,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 50,
    r_300_m: 75,
    r_400_m: 100,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 43,
    e_km_low: 335,
    e_km_up: 376,
    e_mi_low: 540,
    e_mi_up: 605,
    c_km: 309,
    c_mi: 497,
    t_400_m: 115,
    t_km: 289,
    t_mi: 462,
    i_400_m: 106,
    i_km: 266,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 49,
    r_300_m: 74,
    r_400_m: 98,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 44,
    e_km_low: 329,
    e_km_up: 370,
    e_mi_low: 530,
    e_mi_up: 595,
    c_km: 303,
    c_mi: 487,
    t_400_m: 113,
    t_km: 283,
    t_mi: 453,
    i_400_m: 104,
    i_km: 261,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 48,
    r_300_m: 72,
    r_400_m: 96,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 45,
    e_km_low: 323,
    e_km_up: 363,
    e_mi_low: 520,
    e_mi_up: 584,
    c_km: 297,
    c_mi: 478,
    t_400_m: 111,
    t_km: 278,
    t_mi: 445,
    i_400_m: 102,
    i_km: 256,
    i_1200_m: null,
    i_mi: null,
    r_200_m: 47,
    r_300_m: 71,
    r_400_m: 94,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 46,
    e_km_low: 317,
    e_km_up: 357,
    e_mi_low: 511,
    e_mi_up: 574,
    c_km: 291,
    c_mi: 469,
    t_400_m: 109,
    t_km: 273,
    t_mi: 437,
    i_400_m: 100,
    i_km: 252,
    i_1200_m: 300,
    i_mi: null,
    r_200_m: 46,
    r_300_m: 69,
    r_400_m: 92,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 47,
    e_km_low: 312,
    e_km_up: 351,
    e_mi_low: 502,
    e_mi_up: 565,
    c_km: 286,
    c_mi: 460,
    t_400_m: 107,
    t_km: 269,
    t_mi: 429,
    i_400_m: 98,
    i_km: 247,
    i_1200_m: 294,
    i_mi: null,
    r_200_m: 45,
    r_300_m: 68,
    r_400_m: 90,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 48,
    e_km_low: 307,
    e_km_up: 345,
    e_mi_low: 493,
    e_mi_up: 555,
    c_km: 281,
    c_mi: 452,
    t_400_m: 105,
    t_km: 264,
    t_mi: 422,
    i_400_m: 96,
    i_km: 243,
    i_1200_m: 289,
    i_mi: null,
    r_200_m: 44,
    r_300_m: 67,
    r_400_m: 89,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 49,
    e_km_low: 301,
    e_km_up: 340,
    e_mi_low: 485,
    e_mi_up: 546,
    c_km: 276,
    c_mi: 444,
    t_400_m: 103,
    t_km: 260,
    t_mi: 416,
    i_400_m: 95,
    i_km: 239,
    i_1200_m: 285,
    i_mi: null,
    r_200_m: 44,
    r_300_m: 66,
    r_400_m: 88,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 50,
    e_km_low: 296,
    e_km_up: 334,
    e_mi_low: 477,
    e_mi_up: 538,
    c_km: 271,
    c_mi: 437,
    t_400_m: 101,
    t_km: 255,
    t_mi: 410,
    i_400_m: 93,
    i_km: 235,
    i_1200_m: 280,
    i_mi: null,
    r_200_m: 43,
    r_300_m: 65,
    r_400_m: 87,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 51,
    e_km_low: 292,
    e_km_up: 329,
    e_mi_low: 469,
    e_mi_up: 529,
    c_km: 267,
    c_mi: 429,
    t_400_m: 100,
    t_km: 251,
    t_mi: 404,
    i_400_m: 92,
    i_km: 231,
    i_1200_m: 276,
    i_mi: null,
    r_200_m: 43,
    r_300_m: 64,
    r_400_m: 86,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 52,
    e_km_low: 287,
    e_km_up: 324,
    e_mi_low: 462,
    e_mi_up: 521,
    c_km: 262,
    c_mi: 422,
    t_400_m: 98,
    t_km: 247,
    t_mi: 398,
    i_400_m: 91,
    i_km: 228,
    i_1200_m: 272,
    i_mi: null,
    r_200_m: 42,
    r_300_m: 64,
    r_400_m: 85,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 53,
    e_km_low: 283,
    e_km_up: 319,
    e_mi_low: 455,
    e_mi_up: 513,
    c_km: 258,
    c_mi: 416,
    t_400_m: 97,
    t_km: 244,
    t_mi: 392,
    i_400_m: 90,
    i_km: 224,
    i_1200_m: 269,
    i_mi: null,
    r_200_m: 42,
    r_300_m: 63,
    r_400_m: 84,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 54,
    e_km_low: 278,
    e_km_up: 314,
    e_mi_low: 448,
    e_mi_up: 506,
    c_km: 254,
    c_mi: 409,
    t_400_m: 95,
    t_km: 240,
    t_mi: 386,
    i_400_m: 88,
    i_km: 221,
    i_1200_m: 265,
    i_mi: null,
    r_200_m: 41,
    r_300_m: 62,
    r_400_m: 82,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 55,
    e_km_low: 274,
    e_km_up: 310,
    e_mi_low: 441,
    e_mi_up: 498,
    c_km: 250,
    c_mi: 403,
    t_400_m: 94,
    t_km: 236,
    t_mi: 380,
    i_400_m: 87,
    i_km: 217,
    i_1200_m: 261,
    i_mi: null,
    r_200_m: 40,
    r_300_m: 61,
    r_400_m: 81,
    r_600_m: null,
    r_800_m: null,
  },
  {
    vdot: 56,
    e_km_low: 270,
    e_km_up: 305,
    e_mi_low: 435,
    e_mi_up: 491,
    c_km: 246,
    c_mi: 397,
    t_400_m: 93,
    t_km: 233,
    t_mi: 375,
    i_400_m: 86,
    i_km: 214,
    i_1200_m: 258,
    i_mi: null,
    r_200_m: 40,
    r_300_m: 60,
    r_400_m: 80,
    r_600_m: 120,
    r_800_m: null,
  },
  {
    vdot: 57,
    e_km_low: 266,
    e_km_up: 301,
    e_mi_low: 428,
    e_mi_up: 484,
    c_km: 243,
    c_mi: 391,
    t_400_m: 91,
    t_km: 230,
    t_mi: 369,
    i_400_m: 85,
    i_km: 211,
    i_1200_m: 254,
    i_mi: null,
    r_200_m: 39,
    r_300_m: 59,
    r_400_m: 79,
    r_600_m: 117,
    r_800_m: null,
  },
  {
    vdot: 58,
    e_km_low: 262,
    e_km_up: 297,
    e_mi_low: 422,
    e_mi_up: 477,
    c_km: 239,
    c_mi: 385,
    t_400_m: 90,
    t_km: 226,
    t_mi: 364,
    i_400_m: 83,
    i_km: 208,
    i_1200_m: 250,
    i_mi: null,
    r_200_m: 38,
    r_300_m: 58,
    r_400_m: 77,
    r_600_m: 115,
    r_800_m: null,
  },
  {
    vdot: 59,
    e_km_low: 259,
    e_km_up: 293,
    e_mi_low: 416,
    e_mi_up: 471,
    c_km: 236,
    c_mi: 379,
    t_400_m: 89,
    t_km: 223,
    t_mi: 359,
    i_400_m: 82,
    i_km: 205,
    i_1200_m: 247,
    i_mi: null,
    r_200_m: 38,
    r_300_m: 57,
    r_400_m: 76,
    r_600_m: 114,
    r_800_m: null,
  },
  {
    vdot: 60,
    e_km_low: 255,
    e_km_up: 289,
    e_mi_low: 410,
    e_mi_up: 465,
    c_km: 232,
    c_mi: 374,
    t_400_m: 88,
    t_km: 220,
    t_mi: 354,
    i_400_m: 81,
    i_km: 203,
    i_1200_m: 243,
    i_mi: null,
    r_200_m: 37,
    r_300_m: 56,
    r_400_m: 75,
    r_600_m: 112,
    r_800_m: null,
  },
  {
    vdot: 61,
    e_km_low: 251,
    e_km_up: 285,
    e_mi_low: 405,
    e_mi_up: 459,
    c_km: 229,
    c_mi: 369,
    t_400_m: 86,
    t_km: 217,
    t_mi: 350,
    i_400_m: 80,
    i_km: 200,
    i_1200_m: 240,
    i_mi: null,
    r_200_m: 37,
    r_300_m: 55,
    r_400_m: 74,
    r_600_m: 111,
    r_800_m: null,
  },
  {
    vdot: 62,
    e_km_low: 248,
    e_km_up: 281,
    e_mi_low: 399,
    e_mi_up: 453,
    c_km: 226,
    c_mi: 364,
    t_400_m: 85,
    t_km: 214,
    t_mi: 345,
    i_400_m: 79,
    i_km: 197,
    i_1200_m: 237,
    i_mi: null,
    r_200_m: 36,
    r_300_m: 54,
    r_400_m: 73,
    r_600_m: 109,
    r_800_m: null,
  },
  {
    vdot: 63,
    e_km_low: 245,
    e_km_up: 278,
    e_mi_low: 394,
    e_mi_up: 447,
    c_km: 223,
    c_mi: 359,
    t_400_m: 84,
    t_km: 212,
    t_mi: 341,
    i_400_m: 78,
    i_km: 195,
    i_1200_m: 234,
    i_mi: null,
    r_200_m: 36,
    r_300_m: 53,
    r_400_m: 72,
    r_600_m: 108,
    r_800_m: null,
  },
  {
    vdot: 64,
    e_km_low: 242,
    e_km_up: 274,
    e_mi_low: 389,
    e_mi_up: 441,
    c_km: 220,
    c_mi: 354,
    t_400_m: 83,
    t_km: 209,
    t_mi: 336,
    i_400_m: 77,
    i_km: 192,
    i_1200_m: 231,
    i_mi: null,
    r_200_m: 35,
    r_300_m: 52,
    r_400_m: 71,
    r_600_m: 106,
    r_800_m: null,
  },
  {
    vdot: 65,
    e_km_low: 239,
    e_km_up: 271,
    e_mi_low: 384,
    e_mi_up: 436,
    c_km: 217,
    c_mi: 349,
    t_400_m: 82,
    t_km: 206,
    t_mi: 332,
    i_400_m: 76,
    i_km: 190,
    i_1200_m: 228,
    i_mi: null,
    r_200_m: 35,
    r_300_m: 52,
    r_400_m: 70,
    r_600_m: 105,
    r_800_m: null,
  },
  {
    vdot: 66,
    e_km_low: 236,
    e_km_up: 268,
    e_mi_low: 379,
    e_mi_up: 430,
    c_km: 214,
    c_mi: 345,
    t_400_m: 81,
    t_km: 204,
    t_mi: 328,
    i_400_m: 75,
    i_km: 188,
    i_1200_m: 225,
    i_mi: 300,
    r_200_m: 34,
    r_300_m: 51,
    r_400_m: 69,
    r_600_m: 103,
    r_800_m: null,
  },
  {
    vdot: 67,
    e_km_low: 233,
    e_km_up: 264,
    e_mi_low: 375,
    e_mi_up: 425,
    c_km: 211,
    c_mi: 340,
    t_400_m: 80,
    t_km: 201,
    t_mi: 324,
    i_400_m: 74,
    i_km: 185,
    i_1200_m: 222,
    i_mi: 297,
    r_200_m: 34,
    r_300_m: 51,
    r_400_m: 68,
    r_600_m: 102,
    r_800_m: null,
  },
  {
    vdot: 68,
    e_km_low: 230,
    e_km_up: 261,
    e_mi_low: 370,
    e_mi_up: 420,
    c_km: 209,
    c_mi: 336,
    t_400_m: 79,
    t_km: 199,
    t_mi: 320,
    i_400_m: 73,
    i_km: 183,
    i_1200_m: 219,
    i_mi: 293,
    r_200_m: 33,
    r_300_m: 50,
    r_400_m: 67,
    r_600_m: 100,
    r_800_m: null,
  },
  {
    vdot: 69,
    e_km_low: 227,
    e_km_up: 258,
    e_mi_low: 366,
    e_mi_up: 415,
    c_km: 206,
    c_mi: 332,
    t_400_m: 78,
    t_km: 196,
    t_mi: 316,
    i_400_m: 72,
    i_km: 181,
    i_1200_m: 216,
    i_mi: 290,
    r_200_m: 33,
    r_300_m: 49,
    r_400_m: 66,
    r_600_m: 99,
    r_800_m: null,
  },
  {
    vdot: 70,
    e_km_low: 224,
    e_km_up: 255,
    e_mi_low: 361,
    e_mi_up: 410,
    c_km: 204,
    c_mi: 328,
    t_400_m: 77,
    t_km: 194,
    t_mi: 313,
    i_400_m: 71,
    i_km: 179,
    i_1200_m: 214,
    i_mi: 286,
    r_200_m: 32,
    r_300_m: 48,
    r_400_m: 65,
    r_600_m: 97,
    r_800_m: null,
  },
  {
    vdot: 71,
    e_km_low: 222,
    e_km_up: 252,
    e_mi_low: 357,
    e_mi_up: 406,
    c_km: 201,
    c_mi: 324,
    t_400_m: 76,
    t_km: 192,
    t_mi: 309,
    i_400_m: 70,
    i_km: 177,
    i_1200_m: 211,
    i_mi: 283,
    r_200_m: 32,
    r_300_m: 48,
    r_400_m: 64,
    r_600_m: 96,
    r_800_m: null,
  },
  {
    vdot: 72,
    e_km_low: 220,
    e_km_up: 250,
    e_mi_low: 353,
    e_mi_up: 401,
    c_km: 199,
    c_mi: 320,
    t_400_m: 76,
    t_km: 190,
    t_mi: 305,
    i_400_m: 69,
    i_km: 175,
    i_1200_m: 209,
    i_mi: 280,
    r_200_m: 31,
    r_300_m: 47,
    r_400_m: 63,
    r_600_m: 94,
    r_800_m: null,
  },
  {
    vdot: 73,
    e_km_low: 217,
    e_km_up: 247,
    e_mi_low: 349,
    e_mi_up: 397,
    c_km: 196,
    c_mi: 316,
    t_400_m: 75,
    t_km: 188,
    t_mi: 302,
    i_400_m: 69,
    i_km: 173,
    i_1200_m: 207,
    i_mi: 277,
    r_200_m: 31,
    r_300_m: 47,
    r_400_m: 63,
    r_600_m: 93,
    r_800_m: null,
  },
  {
    vdot: 74,
    e_km_low: 214,
    e_km_up: 244,
    e_mi_low: 345,
    e_mi_up: 392,
    c_km: 194,
    c_mi: 312,
    t_400_m: 74,
    t_km: 186,
    t_mi: 299,
    i_400_m: 68,
    i_km: 171,
    i_1200_m: 205,
    i_mi: 274,
    r_200_m: 31,
    r_300_m: 46,
    r_400_m: 62,
    r_600_m: 92,
    r_800_m: null,
  },
  {
    vdot: 75,
    e_km_low: 212,
    e_km_up: 241,
    e_mi_low: 341,
    e_mi_up: 388,
    c_km: 192,
    c_mi: 309,
    t_400_m: 74,
    t_km: 184,
    t_mi: 296,
    i_400_m: 67,
    i_km: 169,
    i_1200_m: 202,
    i_mi: 271,
    r_200_m: 30,
    r_300_m: 46,
    r_400_m: 61,
    r_600_m: 91,
    r_800_m: null,
  },
  {
    vdot: 76,
    e_km_low: 210,
    e_km_up: 238,
    e_mi_low: 338,
    e_mi_up: 384,
    c_km: 190,
    c_mi: 305,
    t_400_m: 73,
    t_km: 182,
    t_mi: 292,
    i_400_m: 66,
    i_km: 168,
    i_1200_m: 200,
    i_mi: 268,
    r_200_m: 30,
    r_300_m: 45,
    r_400_m: 60,
    r_600_m: 90,
    r_800_m: null,
  },
  {
    vdot: 77,
    e_km_low: 208,
    e_km_up: 236,
    e_mi_low: 334,
    e_mi_up: 380,
    c_km: 188,
    c_mi: 302,
    t_400_m: 72,
    t_km: 180,
    t_mi: 289,
    i_400_m: 65,
    i_km: 166,
    i_1200_m: 198,
    i_mi: 265,
    r_200_m: 29,
    r_300_m: 45,
    r_400_m: 59,
    r_600_m: 89,
    r_800_m: 120,
  },
  {
    vdot: 78,
    e_km_low: 205,
    e_km_up: 233,
    e_mi_low: 330,
    e_mi_up: 376,
    c_km: 186,
    c_mi: 298,
    t_400_m: 71,
    t_km: 178,
    t_mi: 286,
    i_400_m: 65,
    i_km: 164,
    i_1200_m: 196,
    i_mi: 263,
    r_200_m: 29,
    r_300_m: 44,
    r_400_m: 59,
    r_600_m: 88,
    r_800_m: 119,
  },
  {
    vdot: 79,
    e_km_low: 203,
    e_km_up: 231,
    e_mi_low: 327,
    e_mi_up: 372,
    c_km: 183,
    c_mi: 295,
    t_400_m: 70,
    t_km: 176,
    t_mi: 283,
    i_400_m: 64,
    i_km: 162,
    i_1200_m: 194,
    i_mi: 260,
    r_200_m: 29,
    r_300_m: 44,
    r_400_m: 58,
    r_600_m: 87,
    r_800_m: 118,
  },
  {
    vdot: 80,
    e_km_low: 201,
    e_km_up: 229,
    e_mi_low: 324,
    e_mi_up: 368,
    c_km: 181,
    c_mi: 292,
    t_400_m: 70,
    t_km: 174,
    t_mi: 281,
    i_400_m: 64,
    i_km: 161,
    i_1200_m: 192,
    i_mi: 257,
    r_200_m: 29,
    r_300_m: 43,
    r_400_m: 58,
    r_600_m: 87,
    r_800_m: 116,
  },
  {
    vdot: 81,
    e_km_low: 199,
    e_km_up: 226,
    e_mi_low: 320,
    e_mi_up: 364,
    c_km: 180,
    c_mi: 289,
    t_400_m: 69,
    t_km: 173,
    t_mi: 278,
    i_400_m: 63,
    i_km: 159,
    i_1200_m: 190,
    i_mi: 255,
    r_200_m: 28,
    r_300_m: 43,
    r_400_m: 57,
    r_600_m: 86,
    r_800_m: 115,
  },
  {
    vdot: 82,
    e_km_low: 197,
    e_km_up: 224,
    e_mi_low: 317,
    e_mi_up: 361,
    c_km: 178,
    c_mi: 286,
    t_400_m: 68,
    t_km: 171,
    t_mi: 275,
    i_400_m: 62,
    i_km: 158,
    i_1200_m: 188,
    i_mi: 252,
    r_200_m: 28,
    r_300_m: 42,
    r_400_m: 56,
    r_600_m: 85,
    r_800_m: 114,
  },
  {
    vdot: 83,
    e_km_low: 195,
    e_km_up: 222,
    e_mi_low: 314,
    e_mi_up: 357,
    c_km: 176,
    c_mi: 283,
    t_400_m: 68,
    t_km: 169,
    t_mi: 272,
    i_400_m: 62,
    i_km: 156,
    i_1200_m: 187,
    i_mi: 250,
    r_200_m: 28,
    r_300_m: 42,
    r_400_m: 56,
    r_600_m: 84,
    r_800_m: 113,
  },
  {
    vdot: 84,
    e_km_low: 193,
    e_km_up: 220,
    e_mi_low: 311,
    e_mi_up: 354,
    c_km: 174,
    c_mi: 280,
    t_400_m: 67,
    t_km: 168,
    t_mi: 270,
    i_400_m: 61,
    i_km: 155,
    i_1200_m: 185,
    i_mi: 248,
    r_200_m: 27,
    r_300_m: 41,
    r_400_m: 55,
    r_600_m: 83,
    r_800_m: 112,
  },
  {
    vdot: 85,
    e_km_low: 191,
    e_km_up: 218,
    e_mi_low: 308,
    e_mi_up: 350,
    c_km: 172,
    c_mi: 277,
    t_400_m: 66,
    t_km: 166,
    t_mi: 267,
    i_400_m: 61,
    i_km: 153,
    i_1200_m: 183,
    i_mi: 245,
    r_200_m: 27,
    r_300_m: 41,
    r_400_m: 55,
    r_600_m: 82,
    r_800_m: 111,
  },
];
export const TimeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];
export const main_color_skin_sister = "#b7849b";
